.font-small {
  font-size: 10px;
}
.font-small-1 {
  font-size: 14px;
}
.font-small-2 {
  font-size: 15px;
}
.font-small-3 {
  font-size: 16px;
}
.font-medium {
  font-size: 18px;
}
.font-medium-1 {
  font-size: 22px;
}
.font-medium-2 {
  font-size: 26px;
}
.font-medium-3 {
  font-size: 28px;
}
.font-large {
  font-size: 30px;
}
.font-large-1 {
  font-size: 40px;
}
.font-large-2 {
  font-size: 45px;
}
.font-large-3 {
  font-size: 60px;
}
:root {
  /* Colors */
  --color-text: #000000;
  --color-background: #f7f7f7;
  --color-link: #c5c9d1;
  --color-link-active: #0066cc;
  --color-white: #ffffff;
  --color-black: #000000;
  /* Backgrounds */
  --background-mq-large: none;--background-mq-medium: none;--background-mq-small: none;--background-mq-extra-small: none;
  --background-repeat-mq-large: no-repeat;--background-repeat-mq-medium: no-repeat;--background-repeat-mq-small: no-repeat;--background-repeat-mq-extra-small: no-repeat;
  --background-x-mq-large: center;--background-x-mq-medium: center;--background-x-mq-small: center;--background-x-mq-extra-small: center;
  --background-y-mq-large: center;--background-y-mq-medium: center;--background-y-mq-small: center;--background-y-mq-extra-small: center;
  --background-size-mq-large: auto;--background-size-mq-medium: auto;--background-size-mq-small: auto;--background-size-mq-extra-small: auto;
  /* Fonts */
  --font-size-mq-large: 16px;
  --font-size-mq-medium: 16px;
  --font-size-mq-small: 16px;
  --font-size-mq-extra-small: 16px;
  --font-stack-playoff: playoff, sans-serif;
  --font-stack-benton: benton-sans, Roboto, Arial, sans-serif;
  --font-stack-roboto: Roboto, Arial, sans-serif;
  --font-stack-avenir: avenir, sans-serrif;
  --font-stack: var(--font-stack-benton);
  --font-weight: initial;
  --font-style: initial;
  --text-decoration: none;
  --text-align-mq-large: left;--text-align-mq-medium: left;--text-align-mq-small: left;--text-align-mq-extra-small: left;
  --line-height-mq-large: 1.6;--line-height-mq-medium: 1.6;--line-height-mq-small: 1.6;--line-height-mq-extra-small: 1.6;
  /* Margins */
  --margin-top-mq-large: 0;--margin-top-mq-medium: 0;--margin-top-mq-small: 0;--margin-top-mq-extra-small: 0;
  --margin-right-mq-large: 0;--margin-right-mq-medium: 0;--margin-right-mq-small: 0;--margin-right-mq-extra-small: 0;
  --margin-bottom-mq-large: 0;--margin-bottom-mq-medium: 0;--margin-bottom-mq-small: 0;--margin-bottom-mq-extra-small: 0;
  --margin-left-mq-large: 0;--margin-left-mq-medium: 0;--margin-left-mq-small: 0;--margin-left-mq-extra-small: 0;
  /* Padding */
  --padding-top-mq-large: 0;--padding-top-mq-medium: 0;--padding-top-mq-small: 0;--padding-top-mq-extra-small: 0;
  --padding-right-mq-large: 0;--padding-right-mq-medium: 0;--padding-right-mq-small: 0;--padding-right-mq-extra-small: 0;
  --padding-bottom-mq-large: 0;--padding-bottom-mq-medium: 0;--padding-bottom-mq-small: 0;--padding-bottom-mq-extra-small: 0;
  --padding-left-mq-large: 0;--padding-left-mq-medium: 0;--padding-left-mq-small: 0;--padding-left-mq-extra-small: 0;
  /* Borders */
  --border-top-width-mq-large: 0;--border-top-width-mq-medium: 0;--border-top-width-mq-small: 0;--border-top-width-mq-extra-small: 0;
  --border-top-style-mq-large: none;--border-top-style-mq-medium: none;--border-top-style-mq-small: none;--border-top-style-mq-extra-small: none;
  --border-top-color-mq-large: currentColor;--border-top-color-mq-medium: currentColor;--border-top-color-mq-small: currentColor;--border-top-color-mq-extra-small: currentColor;
  --border-right-width-mq-large: 0;--border-right-width-mq-medium: 0;--border-right-width-mq-small: 0;--border-right-width-mq-extra-small: 0;
  --border-right-style-mq-large: none;--border-right-style-mq-medium: none;--border-right-style-mq-small: none;--border-right-style-mq-extra-small: none;
  --border-right-color-mq-large: currentColor;--border-right-color-mq-medium: currentColor;--border-right-color-mq-small: currentColor;--border-right-color-mq-extra-small: currentColor;
  --border-bottom-width-mq-large: 0;--border-bottom-width-mq-medium: 0;--border-bottom-width-mq-small: 0;--border-bottom-width-mq-extra-small: 0;
  --border-bottom-style-mq-large: none;--border-bottom-style-mq-medium: none;--border-bottom-style-mq-small: none;--border-bottom-style-mq-extra-small: none;
  --border-bottom-color-mq-large: currentColor;--border-bottom-color-mq-medium: currentColor;--border-bottom-color-mq-small: currentColor;--border-bottom-color-mq-extra-small: currentColor;
  --border-left-width-mq-large: 0;--border-left-width-mq-medium: 0;--border-left-width-mq-small: 0;--border-left-width-mq-extra-small: 0;
  --border-left-style-mq-large: none;--border-left-style-mq-medium: none;--border-left-style-mq-small: none;--border-left-style-mq-extra-small: none;
  --border-left-color-mq-large: currentColor;--border-left-color-mq-medium: currentColor;--border-left-color-mq-small: currentColor;--border-left-color-mq-extra-small: currentColor;
  /* Dimensions */
  --width-mq-large: auto;--width-mq-medium: auto;--width-mq-small: auto;--width-mq-extra-small: auto;
  --max-width-mq-large: none;--max-width-mq-medium: none;--max-width-mq-small: none;--max-width-mq-extra-small: none;
  --min-width-mq-large: auto;--min-width-mq-medium: auto;--min-width-mq-small: auto;--min-width-mq-extra-small: auto;
  /* Height */
  --height-mq-large: auto;--height-mq-medium: auto;--height-mq-small: auto;--height-mq-extra-small: auto;
  /* Display */
  --display-mq-large: initial;--display-mq-medium: initial;--display-mq-small: initial;--display-mq-extra-small: initial;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
.content {
  --color-background: initial;
  --border-top-width-mq-large: 0;
  --border-right-width-mq-large: 0;
  --border-bottom-width-mq-large: 0;
  --border-left-width-mq-large: 0;
  --margin-top-mq-large: 0;--margin-top-mq-medium: 0;--margin-top-mq-small: 0;--margin-top-mq-extra-small: 0;
  --margin-right-mq-large: 0;--margin-right-mq-medium: 0;--margin-right-mq-small: 0;--margin-right-mq-extra-small: 0;
  --margin-bottom-mq-large: 0;--margin-bottom-mq-medium: 0;--margin-bottom-mq-small: 0;--margin-bottom-mq-extra-small: 0;
  --margin-left-mq-large: 0;--margin-left-mq-medium: 0;--margin-left-mq-small: 0;--margin-left-mq-extra-small: 0;
  --padding-top-mq-large: 0;--padding-top-mq-medium: 0;--padding-top-mq-small: 0;--padding-top-mq-extra-small: 0;
  --padding-right-mq-large: 0;--padding-right-mq-medium: 0;--padding-right-mq-small: 0;--padding-right-mq-extra-small: 0;
  --padding-bottom-mq-large: 0;--padding-bottom-mq-medium: 0;--padding-bottom-mq-small: 0;--padding-bottom-mq-extra-small: 0;
  --padding-left-mq-large: 0;--padding-left-mq-medium: 0;--padding-left-mq-small: 0;--padding-left-mq-extra-small: 0;
  --width-mq-large: auto;--width-mq-medium: auto;--width-mq-small: auto;--width-mq-extra-small: auto;
  --max-width-mq-large: none;--max-width-mq-medium: none;--max-width-mq-small: none;--max-width-mq-extra-small: none;
  --min-width-mq-large: auto;--min-width-mq-medium: auto;--min-width-mq-small: auto;--min-width-mq-extra-small: auto;
}
/* LEGACY */
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
  margin: 0;
}
body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: var(--font-size-mq-large);
  font-family: var(--font-stack);
  -webkit-font-smoothing: antialiased;
  background-color: var(--color-background);
  color: var(--color-text);
}
a,
a:link,
a:visited,
a:focus,
a:active {
  --text-decoration: underline;
  display: var(--display-mq-large, inline);
  color: var(--color-link);
  -webkit-text-decoration: var(--text-decoration);
          text-decoration: var(--text-decoration);
  cursor: pointer;
}
@media (max-width: 1024px) {
  a,
  a:link,
  a:visited,
  a:focus,
  a:active {
    display: var(--display-mq-medium, inline);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  a,
  a:link,
  a:visited,
  a:focus,
  a:active {
    display: var(--display-mq-small, inline);
  }
}
@media (max-width: 416px) {
  a,
  a:link,
  a:visited,
  a:focus,
  a:active {
    display: var(--display-mq-extra-small, inline);
  }
}
a:hover,
a:active {
  color: var(--color-link-active);
}
span,
section {
  width: var(--width-mq-large);
}
figure {
  margin: 0;
}
.container .primary-content {
  width: auto;
}
.no-select {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flex-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
hr {
  width: 60px;
  height: 5px;
  background: -webkit-gradient(linear, left top, right top, from(#f6a700), to(#ce8200));
  background: linear-gradient(to right, #f6a700, #ce8200);
  margin: 0 auto 10px auto;
  border: none;
}
body.entitlement-none .entitlement-none,
body.entitlement-monthly .entitlement-none,
body.entitlement-annual .entitlement-none,
body.entitlement-bundle .entitlement-none,
body.entitlement-none .entitlement-monthly,
body.entitlement-monthly .entitlement-monthly,
body.entitlement-annual .entitlement-monthly,
body.entitlement-bundle .entitlement-monthly,
body.entitlement-none .entitlement-annual,
body.entitlement-monthly .entitlement-annual,
body.entitlement-annual .entitlement-annual,
body.entitlement-bundle .entitlement-annual,
body.entitlement-none .entitlement-bundle,
body.entitlement-monthly .entitlement-bundle,
body.entitlement-annual .entitlement-bundle,
body.entitlement-bundle .entitlement-bundle {
  display: none;
}
body.entitlement-none .entitlement-none {
  display: block;
}
body.entitlement-monthly .entitlement-monthly {
  display: block;
}
body.entitlement-annual .entitlement-annual {
  display: block;
}
body.entitlement-bundle .entitlement-bundle {
  display: block;
}
:target {
  scroll-margin-top: 75px;
}
section {
  --background-size-mq-large:cover;--background-size-mq-medium:cover;--background-size-mq-small:cover;--background-size-mq-extra-small:cover;
  --background-repeat-mq-large:no-repeat;--background-repeat-mq-medium:no-repeat;--background-repeat-mq-small:no-repeat;--background-repeat-mq-extra-small:no-repeat;
  background-image: var(--gradient-mq-large, none), var(--background-mq-large);
  background-position: var(--background-x-mq-large) var(--background-y-mq-large);
  background-repeat: var(--background-repeat-mq-large);
  background-size: var(--background-size-mq-large);
  color: var(--color-text);
  background-color: var(--color-background);
  display: var(--display-mq-large, block);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  section {
    background-image: var(--gradient-mq-medium, none), var(--background-mq-medium);
    background-position: var(--background-x-mq-medium) var(--background-y-mq-medium);
    background-repeat: var(--background-repeat-mq-medium);
    background-size: var(--background-size-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section {
    background-image: var(--gradient-mq-small, none), var(--background-mq-small);
    background-position: var(--background-x-mq-small) var(--background-y-mq-small);
    background-repeat: var(--background-repeat-mq-small);
    background-size: var(--background-size-mq-small);
  }
}
@media (max-width: 416px) {
  section {
    background-image: var(--gradient-mq-extra-small, none), var(--background-mq-extra-small);
    background-position: var(--background-x-mq-extra-small) var(--background-y-mq-extra-small);
    background-repeat: var(--background-repeat-mq-extra-small);
    background-size: var(--background-size-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  section {
    display: var(--display-mq-medium, block);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section {
    display: var(--display-mq-small, block);
  }
}
@media (max-width: 416px) {
  section {
    display: var(--display-mq-extra-small, block);
  }
}
@media (max-width: 1024px) {
  section {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  section {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
section.features {
  margin: 0 auto;
}
section.features .legal-copy {
  line-height: 12px;
}
section.logos h1,
section.logos h2,
section.logos h3,
section.logos h4,
section.logos h5,
section.logos h6 {
  --padding-right-mq-large:40px;--padding-right-mq-medium:40px;--padding-right-mq-small:40px;--padding-right-mq-extra-small:40px;
  --padding-left-mq-large:40px;--padding-left-mq-medium:40px;--padding-left-mq-small:40px;--padding-left-mq-extra-small:40px;
}
section.logos p {
  --padding-right-mq-large:40px;--padding-right-mq-medium:40px;--padding-right-mq-small:40px;--padding-right-mq-extra-small:40px;
  --padding-left-mq-large:40px;--padding-left-mq-medium:40px;--padding-left-mq-small:40px;--padding-left-mq-extra-small:40px;
}
button .default-button-styles {
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-family: var(--font-stack-playoff);
  font-weight: 700;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  border: none;
  letter-spacing: 1.4px;
  line-height: 1;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
}
@media (max-width: 1024px) {
  button .default-button-styles {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  button .default-button-styles {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  button .default-button-styles {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
button.primary {
  --color-text: var(--color-black);
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-family: var(--font-stack-playoff);
  font-weight: 700;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  border: none;
  letter-spacing: 1.4px;
  line-height: 1;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  color: var(--color-text);
  background: #fa9800;
  background: -webkit-gradient(linear, left bottom, left top, from(#fa9500), to(#fab200));
  background: linear-gradient(0deg, #fa9500 0%, #fab200 100%);
}
@media (max-width: 1024px) {
  button.primary {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  button.primary {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  button.primary {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
button.primary:hover {
  background: -webkit-gradient(linear, left top, right top, from(#fa9800), to(#fab200));
  background: linear-gradient(90deg, #fa9800 0%, #fab200 100%);
}
button.secondary {
  --color-text: var(--color-white);
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-family: var(--font-stack-playoff);
  font-weight: 700;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  border: none;
  letter-spacing: 1.4px;
  line-height: 1;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  color: var(--color-text);
  background: #0077cc;
  background: -webkit-gradient(linear, left bottom, left top, from(#013265), to(#0077cc));
  background: linear-gradient(0deg, #013265 0%, #0077cc 100%);
}
@media (max-width: 1024px) {
  button.secondary {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  button.secondary {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  button.secondary {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
button.tertiary {
  --color-text: var(--color-white);
  background: transparent;
  -webkit-box-shadow: 0px 0px 0px transparent;
          box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  color: var(--color-text);
  text-shadow: 0px 0px 0px transparent;
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
}
button.disneyPlusBlue {
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-family: var(--font-stack-playoff);
  font-weight: 700;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  border: none;
  letter-spacing: 1.4px;
  line-height: 1;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  --color-text: var(--color-white);
  color: var(--color-text);
  border-radius: 4px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#009cf4), to(#0063e5));
  background-image: linear-gradient(to bottom, #009cf4, #0063e5);
  font-family: avenir, sans-serrif;
  font-style: normal;
}
@media (max-width: 1024px) {
  button.disneyPlusBlue {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  button.disneyPlusBlue {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  button.disneyPlusBlue {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
button.disneyPlusBlue.medium,
button.disneyPlusBlue.large {
  font-size: 18px;
  padding: 1.25rem 0;
  min-width: initial;
  min-height: initial;
}
button.large {
  font-size: 26px;
  padding: 15px 24px 14px;
  min-width: initial;
  min-height: initial;
}
button.medium {
  font-size: 21px;
  padding: 12px 17px 11px;
  min-width: initial;
  min-height: initial;
}
button.small {
  font-size: 18px;
  padding: 9px 14px 8px;
  min-width: initial;
  min-height: initial;
}
button.extra-small {
  font-size: 15px;
  padding: 6px 8px 5px;
  min-width: initial;
  min-height: initial;
}
.section-accordion {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 40px;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
  display: var(--display-mq-large, block);
}
@media (max-width: 1024px) {
  .section-accordion {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-accordion {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .section-accordion {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .section-accordion {
    display: var(--display-mq-medium, block);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-accordion {
    display: var(--display-mq-small, block);
  }
}
@media (max-width: 416px) {
  .section-accordion {
    display: var(--display-mq-extra-small, block);
  }
}
.accordion {
  --color-background: #013265;
  --color-text: #ffffff;
  --color-border: #cacaca;
  --bg-offset-desktop: 0;
  --bg-offset-mobile: 0;
  --bg-image-desktop: none;
  --bg-image-tablet: none;
  --bg-image-mobile: none;
  --border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  list-style: none;
  margin: 0 auto;
  max-width: 1150px;
  max-height: 600px;
  padding: 0;
  overflow: hidden;
}
.accordion-header {
  max-width: 1150px;
  margin: 0 auto;
}
.accordion-header h2,
.accordion-header h3,
.accordion-header h4 {
  color: var(--headline-color-section);
  display: inline-block;
  font-size: 32px;
  font-family: playoff, sans-serif;
  letter-spacing: 1px;
  max-width: 60rem;
  width: 100%;
}
.accordion-header-copy {
  font-family: -apple-system, BlinkMacSystemFont, Verdana, Geneva, Tahoma, sans-serif;
  display: inline-block;
  margin-bottom: 1rem;
  max-width: 60rem;
  width: 100%;
}
.accordion-header-copy.wysiwyg {
  max-width: 60rem;
}
.accordion-panel {
  --panel-min-height-desktop: 600px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  border-right: 2px solid var(--color-border);
  color: var(--color-text);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: var(--panel-min-height-desktop);
  overflow: hidden;
  position: relative;
  -webkit-transition: all 500ms ease, background-position 150ms linear;
  transition: all 500ms ease, background-position 150ms linear;
}
.accordion-panel:first-of-type {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.accordion-panel:last-of-type {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  border-right: none;
}
.accordion-panel::before {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  content: attr(data-sidebar);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  font: bold italic 2.5rem/1 playoff, sans-serif;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  letter-spacing: 0.15rem;
  opacity: 0;
  position: absolute;
  text-transform: uppercase;
  -webkit-transform: rotate(90deg) translateY(0.1em);
          transform: rotate(90deg) translateY(0.1em);
  -webkit-transition: opacity 150ms ease;
  transition: opacity 150ms ease;
  white-space: nowrap;
  width: 100%;
}
.accordion-panel::after {
  background-color: var(--color-background);
  bottom: 0;
  content: '';
  display: none;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.accordion-panel[data-active="true"] {
  background-blend-mode: unset;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
.accordion-panel[data-active="false"] {
  background-position: 0 0, 0 0, var(--bg-offset-desktop) 0;
  -ms-flex-preferred-size: 145px;
      flex-basis: 145px;
}
.accordion-panel[data-active="false"]::before {
  opacity: 1;
  z-index: 2;
}
.accordion-panel[data-active="false"]::after {
  display: block;
}
@media (min-width: 1024px) {
  .accordion-panel:not([data-active]):hover,
  .accordion-panel:not([data-active]):active {
    background-size: 0, cover;
  }
}
.sidebar-image::before {
  background-image: var(--sidebar-image);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  content: '';
  height: 100%;
}
@media (min-width: 1025px) {
  .sidebar-image::before {
    height: 0;
    margin-top: -100%;
    padding-top: 100%;
    top: 0;
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: var(--panel-min-height-desktop);
  }
}
@media (max-width: 1024px) {
  .sidebar-image::before {
    height: 100%;
    margin-top: 0;
    padding-top: 0;
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .sidebar-image::before {
    --background-offset: 75px;
    background-size: calc(100% - (var(--background-offset) * 2));
  }
}
@media (max-width: 416px) {
  .sidebar-image::before {
    --background-offset: 40px;
  }
}
.accordion-copy {
  --text-align-mq-large:left;--text-align-mq-medium:left;--text-align-mq-small:left;--text-align-mq-extra-small:left;
  -ms-flex-item-align: end;
      align-self: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font: 15px/1.5 benton-sans, Roboto, Arial, sans-serif;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 2rem;
  max-width: 45%;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: opacity 150ms -150ms ease;
  transition: opacity 150ms -150ms ease;
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  .accordion-copy {
    text-align: var(--text-align-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .accordion-copy {
    text-align: var(--text-align-mq-small);
  }
}
@media (max-width: 416px) {
  .accordion-copy {
    text-align: var(--text-align-mq-extra-small);
  }
}
.accordion-copy h2 {
  font: bold italic 60px/0.9 playoff, sans-serif;
  letter-spacing: 1.68px;
  margin: 0 0 25px;
  text-transform: uppercase;
}
.accordion-copy p {
  font-size: inherit;
  margin: 0 0 1rem;
}
.accordion-panel[data-active="true"] .accordion-copy {
  opacity: 1;
  -webkit-transition-delay: 400ms;
          transition-delay: 400ms;
}
.accordion-logo {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-bottom: 25px;
  max-width: 200px;
}
.accordion-cta {
  -ms-flex-line-pack: center;
      align-content: center;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 0 1rem;
  padding: 0 40px;
  position: relative;
  z-index: 2;
  width: 100%;
}
.accordion-cta:before {
  font-size: 30px;
}
.accordion-cta:after {
  font-size: 45px;
}
.accordion-cta::before,
.accordion-cta::after {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  height: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 40px;
  position: absolute;
  bottom: 0;
  font-weight: bold;
}
.accordion-cta::before {
  border: 3px solid currentColor;
  border-radius: 100%;
  content: "+";
}
.accordion-cta::after {
  border: 3px solid transparent;
}
.accordion-panel[data-active="true"] .accordion-cta::before {
  content: "";
}
.accordion-panel[data-active="true"] .accordion-cta::after {
  content: "-";
}
.accordion-cta-logo {
  -ms-flex-item-align: center;
      align-self: center;
  margin-bottom: 75px;
  max-width: 200px;
  opacity: 1;
  -webkit-transition: opacity 150ms ease;
  transition: opacity 150ms ease;
}
.accordion-panel[data-active] .accordion-cta-logo {
  opacity: 0;
}
/*Tablet View*/
@media (max-width: 1024px) {
  .accordion-header {
    max-width: 750px;
  }
  .accordion {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    height: 850px;
    max-width: 750px;
    max-height: none;
  }
  .accordion-panel {
    background-position: 0 0, 100% 0;
    background-size: cover, cover;
    border-bottom: 2px solid var(--bg-color-section);
    border-right: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    min-height: 130px;
  }
  .accordion-panel:first-of-type {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }
  .accordion-panel:last-of-type {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
  .accordion-panel::before {
    -ms-flex-item-align: center;
        align-self: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-height: 0;
    opacity: 0;
    text-align: center;
    -webkit-transform: none;
            transform: none;
    -webkit-transition: opacity 150ms ease;
    transition: opacity 150ms ease;
    width: 100%;
    white-space: normal;
    padding: 0 85px;
  }
  .accordion-panel[data-active="false"] {
    background-position: 0 0, 0 0, 100% var(--bg-offset-mobile);
  }
  .accordion-panel[data-active="false"]::before {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  .accordion-copy {
    -ms-flex-item-align: stretch;
        align-self: stretch;
    font-size: 16px;
    margin-left: 10%;
  }
  .accordion-copy h2 {
    font-size: 40px;
  }
  .accordion-cta {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin: 0 5%;
    padding: 40px 0;
  }
  .accordion-cta .accordion-cta-logo {
    -ms-flex-item-align: center;
        align-self: center;
    margin-bottom: 0;
    margin-left: 95px;
  }
  .accordion-cta::before,
  .accordion-cta::after {
    bottom: auto;
    left: 0;
  }
  [data-active] .accordion-cta {
    width: 0%;
  }
}
/*Mobile View*/
@media (max-width: 900px) {
  .accordion {
    --border-radius: 25px;
    height: 700px;
  }
  .accordion-section {
    padding: 40px 25px;
  }
  .accordion-panel[data-active="false"] {
    -ms-flex-preferred-size: 65px;
        flex-basis: 65px;
  }
  .accordion-panel[data-active="false"]::before {
    font-size: 1.5rem;
    padding: 0 65px;
  }
  .accordion-cta {
    padding: 25px 0;
  }
  .accordion-cta:before,
  .accordion-cta:after {
    font-size: 18px;
    padding-left: 0.5px;
    height: 20px;
    width: 20px;
  }
  [data-active="true"] .accordion-cta:before,
  [data-active="true"] .accordion-cta:after {
    bottom: 15px;
  }
  .accordion-cta:before {
    border-width: 2px;
  }
  .accordion-cta .accordion-cta-logo {
    margin-left: 50px;
    max-width: 150px;
  }
  .accordion-logo {
    max-width: 150px;
  }
  .accordion-copy {
    font-size: 14px;
    letter-spacing: 0.6px;
    margin-left: -75px;
  }
  .accordion-copy h2 {
    font-size: 35px;
    margin-bottom: 15px;
  }
  [data-active] .accordion-copy {
    margin-left: 10px;
    margin-right: 1rem;
    max-width: none;
  }
}
/**
 * Safari features a bug forcing images defined in CSS varibles to
 * rerender on resize, causing flicker.
 * https://bugs.webkit.org/show_bug.cgi?id=195628
 *
 * The below styles are ready to serve once this bug has been addressed.
 * Remove the styleParser workarounds in the component library.
 * Uncomment the import in `accordion.less`.
 * These styles will kick in.
 */
.accordion-panel {
  --gradient-desktop: linear-gradient(90deg, transparent -200%, var(--color-background) 400%);
  --gradient-desktop-active: linear-gradient(90deg, transparent -35%, var(--color-background));
  --gradient-mobile: linear-gradient(180deg, transparent -200%, var(--color-background) 400%);
  --gradient-mobile-active: radial-gradient(circle at 100% 0, transparent 35%, var(--color-background));
  background-image: var(--gradient-desktop), var(--background-mq-large);
}
@media (max-width: 1024px) {
  .accordion-panel {
    background-image: var(--gradient-mobile), var(--background-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .accordion-panel {
    background-image: var(--gradient-mobile), var(--background-mq-small);
  }
}
@media (max-width: 416px) {
  .accordion-panel {
    background-image: var(--gradient-mobile), var(--background-mq-small);
  }
}
.accordion-panel[data-active="true"] {
  background-image: var(--gradient-desktop-active), var(--background-mq-large);
}
@media (max-width: 1024px) {
  .accordion-panel[data-active="true"] {
    background-image: var(--gradient-mobile-active), var(--background-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .accordion-panel[data-active="true"] {
    background-image: var(--gradient-mobile-active), var(--background-mq-small);
  }
}
@media (max-width: 416px) {
  .accordion-panel[data-active="true"] {
    background-image: var(--gradient-mobile-active), var(--background-mq-small);
  }
}
.grid.banner {
  --color-background: #1d3563;
  --color-text: #f7f7f7;
  --font-stack: var(--font-stack-playoff);
  --font-weight: bold;
  --font-style: italic;
  --font-size-mq-large: 16px;
  --font-size-mq-medium: 15px;
  --font-size-mq-small: 14px;
  --font-size-mq-extra-small: 10px;
  --padding-top-mq-large: 10px;
  --padding-right-mq-large: 20px;
  --padding-bottom-mq-large: 10px;
  --padding-left-mq-large: 7px;
  --text-align-mq-small: center;
  --text-align-mq-extra-small: center;
  min-height: 102px;
  overflow: hidden;
  width: 100%;
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
  color: var(--color-text);
  background-color: var(--color-background);
  /*
  // Note: safe to use with image backgrounds.
  // Image-based backgrounds generate inline and will cascade over the gradient.
  */
  background-image: var(--gradient-mq-large, none);
}
@media (max-width: 1024px) {
  .grid.banner {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .grid.banner {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .grid.banner {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .grid.banner {
    background-image: var(--gradient-mq-medium, var(--gradient-mq-large, none));
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .grid.banner {
    background-image: var(--gradient-mq-small, var(--gradient-mq-medium, var(--gradient-mq-large, none)));
  }
}
@media (max-width: 416px) {
  .grid.banner {
    background-image: var(--gradient-mq-extra-small, var(--gradient-mq-small, var(--gradient-mq-medium, var(--gradient-mq-large, none))));
  }
}
.grid.banner .grid-item {
  max-height: 100%;
  -ms-flex-wrap: unset;
      flex-wrap: unset;
}
.grid.banner .grid-item img {
  max-width: 92%;
  height: 100%;
}
.grid.banner .grid-item .span {
  letter-spacing: 1px;
}
@media (max-width: 416px) {
  .grid.banner .grid-item .span {
    max-width: 80%;
  }
}
.content {
  color: var(--color-text);
  background-color: var(--color-background);
  display: var(--display-mq-large, block);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
  /*
  // Note: safe to use with image backgrounds.
  // Image-based backgrounds generate inline and will cascade over the gradient.
  */
  background-image: var(--gradient-mq-large, none);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
  width: var(--width-mq-large);
  max-width: var(--max-width-mq-large);
  min-width: var(--min-width-mq-large);
}
@media (max-width: 1024px) {
  .content {
    display: var(--display-mq-medium, block);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .content {
    display: var(--display-mq-small, block);
  }
}
@media (max-width: 416px) {
  .content {
    display: var(--display-mq-extra-small, block);
  }
}
@media (max-width: 1024px) {
  .content {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .content {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .content {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .content {
    background-image: var(--gradient-mq-medium, var(--gradient-mq-large, none));
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .content {
    background-image: var(--gradient-mq-small, var(--gradient-mq-medium, var(--gradient-mq-large, none)));
  }
}
@media (max-width: 416px) {
  .content {
    background-image: var(--gradient-mq-extra-small, var(--gradient-mq-small, var(--gradient-mq-medium, var(--gradient-mq-large, none))));
  }
}
@media (max-width: 1024px) {
  .content {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .content {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .content {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .content {
    width: var(--width-mq-medium);
    max-width: var(--max-width-mq-medium);
    min-width: var(--min-width-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .content {
    width: var(--width-mq-small);
    max-width: var(--max-width-mq-small);
    min-width: var(--min-width-mq-small);
  }
}
@media (max-width: 416px) {
  .content {
    width: var(--width-mq-extra-small);
    max-width: var(--max-width-mq-extra-small);
    min-width: var(--min-width-mq-extra-small);
  }
}
.spacer {
  --display-mq-large:block;--display-mq-medium:block;--display-mq-small:block;--display-mq-extra-small:block;
  display: var(--display-mq-large, block);
}
@media (max-width: 1024px) {
  .spacer {
    display: var(--display-mq-medium, block);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .spacer {
    display: var(--display-mq-small, block);
  }
}
@media (max-width: 416px) {
  .spacer {
    display: var(--display-mq-extra-small, block);
  }
}
.countdown-clock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 0 17px;
  justify-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  --background-mq-large:initial;--background-mq-medium:initial;--background-mq-small:initial;--background-mq-extra-small:initial;
  --gradient-mq-large:initial;--gradient-mq-medium:initial;--gradient-mq-small:initial;--gradient-mq-extra-small:initial;
  --color-background: transparent;
  background-image: var(--gradient-mq-large, none), var(--background-mq-large);
  background-position: var(--background-x-mq-large) var(--background-y-mq-large);
  background-repeat: var(--background-repeat-mq-large);
  background-size: var(--background-size-mq-large);
  color: var(--color-text);
  background-color: var(--color-background);
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
  width: var(--width-mq-large);
  max-width: var(--max-width-mq-large);
  min-width: var(--min-width-mq-large);
}
@media (max-width: 1024px) {
  .countdown-clock {
    background-image: var(--gradient-mq-medium, none), var(--background-mq-medium);
    background-position: var(--background-x-mq-medium) var(--background-y-mq-medium);
    background-repeat: var(--background-repeat-mq-medium);
    background-size: var(--background-size-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .countdown-clock {
    background-image: var(--gradient-mq-small, none), var(--background-mq-small);
    background-position: var(--background-x-mq-small) var(--background-y-mq-small);
    background-repeat: var(--background-repeat-mq-small);
    background-size: var(--background-size-mq-small);
  }
}
@media (max-width: 416px) {
  .countdown-clock {
    background-image: var(--gradient-mq-extra-small, none), var(--background-mq-extra-small);
    background-position: var(--background-x-mq-extra-small) var(--background-y-mq-extra-small);
    background-repeat: var(--background-repeat-mq-extra-small);
    background-size: var(--background-size-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .countdown-clock {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .countdown-clock {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .countdown-clock {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .countdown-clock {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .countdown-clock {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .countdown-clock {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .countdown-clock {
    width: var(--width-mq-medium);
    max-width: var(--max-width-mq-medium);
    min-width: var(--min-width-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .countdown-clock {
    width: var(--width-mq-small);
    max-width: var(--max-width-mq-small);
    min-width: var(--min-width-mq-small);
  }
}
@media (max-width: 416px) {
  .countdown-clock {
    width: var(--width-mq-extra-small);
    max-width: var(--max-width-mq-extra-small);
    min-width: var(--min-width-mq-extra-small);
  }
}
.countdown-clock__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.countdown-clock__label {
  color: var(--color-text);
  letter-spacing: 1.2px;
  height: 18px;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  .countdown-clock__label {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .countdown-clock__label {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .countdown-clock__label {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
.countdown-clock__value {
  color: var(--color-text);
  letter-spacing: 2.72px;
  height: 49px;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  .countdown-clock__value {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .countdown-clock__value {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .countdown-clock__value {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
section.devices {
  --margin-top-mq-large: 0;
  --margin-right-mq-large: auto;
  --margin-bottom-mq-large: 0;
  --margin-left-mq-large: auto;
  --padding-top-mq-large:40px;--padding-top-mq-medium:40px;--padding-top-mq-small:40px;--padding-top-mq-extra-small:40px;
  --padding-right-mq-large:20px;--padding-right-mq-medium:20px;--padding-right-mq-small:20px;--padding-right-mq-extra-small:20px;
  --padding-bottom-mq-large:40px;--padding-bottom-mq-medium:40px;--padding-bottom-mq-small:40px;--padding-bottom-mq-extra-small:40px;
  --padding-left-mq-large:20px;--padding-left-mq-medium:20px;--padding-left-mq-small:20px;--padding-left-mq-extra-small:20px;
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  text-align: var(--text-align-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
  border-top: var(--border-top-width-mq-large) var(--border-top-style-mq-large) var(--border-top-color-mq-large);
  border-right: var(--border-right-width-mq-large) var(--border-right-style-mq-large) var(--border-right-color-mq-large);
  border-bottom: var(--border-bottom-width-mq-large) var(--border-bottom-style-mq-large) var(--border-bottom-color-mq-large);
  border-left: var(--border-left-width-mq-large) var(--border-left-style-mq-large) var(--border-left-color-mq-large);
}
@media (max-width: 1024px) {
  section.devices {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.devices {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  section.devices {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  section.devices {
    border-top: var(--border-top-width-mq-medium) var(--border-top-style-mq-medium) var(--border-top-color-mq-medium);
    border-right: var(--border-right-width-mq-medium) var(--border-right-style-mq-medium) var(--border-right-color-mq-medium);
    border-bottom: var(--border-bottom-width-mq-medium) var(--border-bottom-style-mq-medium) var(--border-bottom-color-mq-medium);
    border-left: var(--border-left-width-mq-medium) var(--border-left-style-mq-medium) var(--border-left-color-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.devices {
    border-top: var(--border-top-width-mq-small) var(--border-top-style-mq-small) var(--border-top-color-mq-small);
    border-right: var(--border-right-width-mq-small) var(--border-right-style-mq-small) var(--border-right-color-mq-small);
    border-bottom: var(--border-bottom-width-mq-small) var(--border-bottom-style-mq-small) var(--border-bottom-color-mq-small);
    border-left: var(--border-left-width-mq-small) var(--border-left-style-mq-small) var(--border-left-color-mq-small);
  }
}
@media (max-width: 416px) {
  section.devices {
    border-top: var(--border-top-width-mq-extra-small) var(--border-top-style-mq-extra-small) var(--border-top-color-mq-extra-small);
    border-right: var(--border-right-width-mq-extra-small) var(--border-right-style-mq-extra-small) var(--border-right-color-mq-extra-small);
    border-bottom: var(--border-bottom-width-mq-extra-small) var(--border-bottom-style-mq-extra-small) var(--border-bottom-color-mq-extra-small);
    border-left: var(--border-left-width-mq-extra-small) var(--border-left-style-mq-extra-small) var(--border-left-color-mq-extra-small);
  }
}
section.devices hr {
  --margin-top-mq-large:0;--margin-top-mq-medium:0;--margin-top-mq-small:0;--margin-top-mq-extra-small:0;
  --margin-right-mq-large:auto;--margin-right-mq-medium:auto;--margin-right-mq-small:auto;--margin-right-mq-extra-small:auto;
  --margin-bottom-mq-large:0;--margin-bottom-mq-medium:0;--margin-bottom-mq-small:0;--margin-bottom-mq-extra-small:0;
  --margin-left-mq-large:auto;--margin-left-mq-medium:auto;--margin-left-mq-small:auto;--margin-left-mq-extra-small:auto;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
}
@media (max-width: 1024px) {
  section.devices hr {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.devices hr {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  section.devices hr {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
section.devices h1,
section.devices h2,
section.devices h3,
section.devices h4,
section.devices h5,
section.devices h6 {
  --font-large: 30px;
  --font-size-mq-large:var(--font-large);--font-size-mq-medium:var(--font-large);--font-size-mq-small:var(--font-large);--font-size-mq-extra-small:var(--font-large);
  letter-spacing: 0;
}
section.devices span {
  --line-height-mq-large: 24px;
  --color-text: #90959e;
  --font-small-3: 16px;
  --font-small-1: 14px;
  --font-size-mq-large:var(--font-small-3);--font-size-mq-medium:var(--font-small-3);--font-size-mq-small:var(--font-small-3);--font-size-mq-extra-small:var(--font-small-1);
  color: var(--color-text);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  section.devices span {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.devices span {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  section.devices span {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
section.devices .grid.even-spaced-grid {
  --max-width-mq-large: 1440px;
  --font-medium: 18px;
  --justify-content-mq-large: space-around;
  max-width: var(--max-width-mq-large);
  margin: 0 auto;
  --font-size-mq-large:var(--font-medium);--font-size-mq-medium:var(--font-medium);--font-size-mq-small:var(--font-medium);--font-size-mq-extra-small:var(--font-medium);
  --line-height-mq-large:1.5;--line-height-mq-medium:1.5;--line-height-mq-small:1.5;--line-height-mq-extra-small:1.5;
  -webkit-box-pack: var(--justify-content-mq-large);
      -ms-flex-pack: var(--justify-content-mq-large);
          justify-content: var(--justify-content-mq-large);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  section.devices .grid.even-spaced-grid {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.devices .grid.even-spaced-grid {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  section.devices .grid.even-spaced-grid {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
section.devices .grid.even-spaced-grid span {
  --font-size-mq-large: 18px;
  --font-stack: var(--font-stack-playoff);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
}
section.devices .grid.even-spaced-grid .grid-item {
  --margin-top-mq-large: 50px;
  margin-top: var(--margin-top-mq-large);
  padding: 0 22px;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.devices .grid.even-spaced-grid .grid-item {
    padding-left: 5px;
    padding-right: 5px;
  }
}
section.devices .grid.even-spaced-grid .grid-item figure {
  --gradient-mq-large:none;--gradient-mq-medium:none;--gradient-mq-small:none;--gradient-mq-extra-small:none;
}
section.devices .grid.even-spaced-grid .grid-item img {
  width: 230px;
}
section.devices .grid.even-spaced-grid .grid-item .image-with-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
section.devices .grid.even-spaced-grid .grid-item .image-with-label .lazy-picture {
  --margin-bottom-mq-large: 10px;
  margin-bottom: var(--margin-bottom-mq-large);
}
section.originals {
  padding: 40px 20px;
}
section.originals .grid.even-spaced-grid .grid-item {
  padding: 10px;
}
section.fantasy {
  padding: 0 20px;
}
section.fantasy h1,
section.fantasy h2,
section.fantasy h3,
section.fantasy h4,
section.fantasy h5,
section.fantasy h6 {
  --text-align-mq-extra-small: center;
}
section.fantasy .grid.even-spaced-grid {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}
section.fantasy .grid.even-spaced-grid .grid-item {
  display: inline-table;
}
section.fantasy .grid.even-spaced-grid .grid-item .image-with-label {
  max-width: 100%;
}
section.fantasy .grid.even-spaced-grid .grid-item figcaption {
  padding: 0 15px;
  overflow-wrap: break-word;
}
section.fantasy .grid.even-spaced-grid .grid-item .heading {
  margin-bottom: 4px;
  line-height: 1.3;
}
section.fantasy .grid.even-spaced-grid .grid-item picture img {
  height: 60px;
  width: 60px;
}
header {
  color: var(--color-text);
  background-color: var(--color-background);
}
header nav {
  max-width: 100%;
  padding: 20px;
}
header nav img {
  max-width: 122px;
}
header nav button.tertiary {
  color: var(--color-text);
  font-family: playoff-regular;
  text-transform: uppercase;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  --line-height-mq-large: 0.9;
  --font-style: italic;
  --font-weight: bold;
  --font-stack: var(--font-stack-playoff);
  text-transform: uppercase;
  border-top: var(--border-top-width-mq-large) var(--border-top-style-mq-large) var(--border-top-color-mq-large);
  border-right: var(--border-right-width-mq-large) var(--border-right-style-mq-large) var(--border-right-color-mq-large);
  border-bottom: var(--border-bottom-width-mq-large) var(--border-bottom-style-mq-large) var(--border-bottom-color-mq-large);
  border-left: var(--border-left-width-mq-large) var(--border-left-style-mq-large) var(--border-left-color-mq-large);
  color: var(--color-text);
  background-color: var(--color-background);
  display: var(--display-mq-large, block);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
  width: var(--width-mq-large);
  max-width: var(--max-width-mq-large);
  min-width: var(--min-width-mq-large);
  letter-spacing: var(--letter-spacing-mq-large);
}
@media (max-width: 1024px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    border-top: var(--border-top-width-mq-medium) var(--border-top-style-mq-medium) var(--border-top-color-mq-medium);
    border-right: var(--border-right-width-mq-medium) var(--border-right-style-mq-medium) var(--border-right-color-mq-medium);
    border-bottom: var(--border-bottom-width-mq-medium) var(--border-bottom-style-mq-medium) var(--border-bottom-color-mq-medium);
    border-left: var(--border-left-width-mq-medium) var(--border-left-style-mq-medium) var(--border-left-color-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    border-top: var(--border-top-width-mq-small) var(--border-top-style-mq-small) var(--border-top-color-mq-small);
    border-right: var(--border-right-width-mq-small) var(--border-right-style-mq-small) var(--border-right-color-mq-small);
    border-bottom: var(--border-bottom-width-mq-small) var(--border-bottom-style-mq-small) var(--border-bottom-color-mq-small);
    border-left: var(--border-left-width-mq-small) var(--border-left-style-mq-small) var(--border-left-color-mq-small);
  }
}
@media (max-width: 416px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    border-top: var(--border-top-width-mq-extra-small) var(--border-top-style-mq-extra-small) var(--border-top-color-mq-extra-small);
    border-right: var(--border-right-width-mq-extra-small) var(--border-right-style-mq-extra-small) var(--border-right-color-mq-extra-small);
    border-bottom: var(--border-bottom-width-mq-extra-small) var(--border-bottom-style-mq-extra-small) var(--border-bottom-color-mq-extra-small);
    border-left: var(--border-left-width-mq-extra-small) var(--border-left-style-mq-extra-small) var(--border-left-color-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: var(--display-mq-medium, block);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: var(--display-mq-small, block);
  }
}
@media (max-width: 416px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: var(--display-mq-extra-small, block);
  }
}
@media (max-width: 1024px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: var(--width-mq-medium);
    max-width: var(--max-width-mq-medium);
    min-width: var(--min-width-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: var(--width-mq-small);
    max-width: var(--max-width-mq-small);
    min-width: var(--min-width-mq-small);
  }
}
@media (max-width: 416px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: var(--width-mq-extra-small);
    max-width: var(--max-width-mq-extra-small);
    min-width: var(--min-width-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: var(--letter-spacing-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: var(--letter-spacing-mq-small);
  }
}
@media (max-width: 416px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: var(--letter-spacing-mq-extra-small);
  }
}
h1 {
  --font-size-mq-large:60px;--font-size-mq-medium:45px;--font-size-mq-small:40px;--font-size-mq-extra-small:40px;
  --letter-spacing-mq-large:6px;--letter-spacing-mq-medium:6px;--letter-spacing-mq-small:6px;--letter-spacing-mq-extra-small:6px;
  --letter-spacing: 6px;
}
h2 {
  --font-size-mq-large:45px;--font-size-mq-medium:40px;--font-size-mq-small:25px;--font-size-mq-extra-small:25px;
  --letter-spacing-mq-large:4.5px;--letter-spacing-mq-medium:4.5px;--letter-spacing-mq-small:4.5px;--letter-spacing-mq-extra-small:4.5px;
}
h3 {
  --font-size-mq-large:40px;--font-size-mq-medium:25px;--font-size-mq-small:16px;--font-size-mq-extra-small:16px;
  --letter-spacing-mq-large:4px;--letter-spacing-mq-medium:4px;--letter-spacing-mq-small:4px;--letter-spacing-mq-extra-small:4px;
}
h4 {
  --font-size-mq-large:40px;--font-size-mq-medium:25px;--font-size-mq-small:16px;--font-size-mq-extra-small:16px;
  --letter-spacing-mq-large:0px;--letter-spacing-mq-medium:0px;--letter-spacing-mq-small:0px;--letter-spacing-mq-extra-small:0px;
  --font-stack: var(--font-stack-benton);
  --font-style: normal;
  text-transform: none;
}
h5 {
  --font-size-mq-large:25px;--font-size-mq-medium:16px;--font-size-mq-small:16px;--font-size-mq-extra-small:16px;
  --letter-spacing-mq-large:2.5px;--letter-spacing-mq-medium:2.5px;--letter-spacing-mq-small:2.5px;--letter-spacing-mq-extra-small:2.5px;
}
h6 {
  --font-size-mq-large:16px;--font-size-mq-medium:16px;--font-size-mq-small:16px;--font-size-mq-extra-small:16px;
  --letter-spacing-mq-large:1.56px;--letter-spacing-mq-medium:1.56px;--letter-spacing-mq-small:1.56px;--letter-spacing-mq-extra-small:1.56px;
}
.section-hero {
  --color-background: #000000;
  --color-text: #ffffff;
  --padding-left-mq-large:25px;--padding-left-mq-medium:25px;--padding-left-mq-small:0px;--padding-left-mq-extra-small:0px;
  --padding-right-mq-large:25px;--padding-right-mq-medium:25px;--padding-right-mq-small:0px;--padding-right-mq-extra-small:0px;
  --height-mq-large:100vh;--height-mq-medium:100vh;--height-mq-small:100vh;--height-mq-extra-small:100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  min-height: calc(var(--height-mq-large) - var(--hero-height, 0px));
  border-top: var(--border-top-width-mq-large) var(--border-top-style-mq-large) var(--border-top-color-mq-large);
  border-right: var(--border-right-width-mq-large) var(--border-right-style-mq-large) var(--border-right-color-mq-large);
  border-bottom: var(--border-bottom-width-mq-large) var(--border-bottom-style-mq-large) var(--border-bottom-color-mq-large);
  border-left: var(--border-left-width-mq-large) var(--border-left-style-mq-large) var(--border-left-color-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (min-width: 1025px) {
  .section-hero {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
@media (max-width: 1024px) {
  .section-hero {
    min-height: calc(var(--height-mq-medium) - var(--hero-height, 0px));
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-hero {
    min-height: calc(var(--height-mq-small) - var(--hero-height, 0px));
  }
}
@media (max-width: 416px) {
  .section-hero {
    min-height: calc(var(--height-mq-extra-small) - var(--hero-height, 0px));
  }
}
@media (max-width: 1024px) {
  .section-hero {
    border-top: var(--border-top-width-mq-medium) var(--border-top-style-mq-medium) var(--border-top-color-mq-medium);
    border-right: var(--border-right-width-mq-medium) var(--border-right-style-mq-medium) var(--border-right-color-mq-medium);
    border-bottom: var(--border-bottom-width-mq-medium) var(--border-bottom-style-mq-medium) var(--border-bottom-color-mq-medium);
    border-left: var(--border-left-width-mq-medium) var(--border-left-style-mq-medium) var(--border-left-color-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-hero {
    border-top: var(--border-top-width-mq-small) var(--border-top-style-mq-small) var(--border-top-color-mq-small);
    border-right: var(--border-right-width-mq-small) var(--border-right-style-mq-small) var(--border-right-color-mq-small);
    border-bottom: var(--border-bottom-width-mq-small) var(--border-bottom-style-mq-small) var(--border-bottom-color-mq-small);
    border-left: var(--border-left-width-mq-small) var(--border-left-style-mq-small) var(--border-left-color-mq-small);
  }
}
@media (max-width: 416px) {
  .section-hero {
    border-top: var(--border-top-width-mq-extra-small) var(--border-top-style-mq-extra-small) var(--border-top-color-mq-extra-small);
    border-right: var(--border-right-width-mq-extra-small) var(--border-right-style-mq-extra-small) var(--border-right-color-mq-extra-small);
    border-bottom: var(--border-bottom-width-mq-extra-small) var(--border-bottom-style-mq-extra-small) var(--border-bottom-color-mq-extra-small);
    border-left: var(--border-left-width-mq-extra-small) var(--border-left-style-mq-extra-small) var(--border-left-color-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .section-hero {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-hero {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .section-hero {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
.section-hero a {
  --text-decoration: underline;
  --color-text: #ffffff;
}
.section-hero > * {
  position: relative;
  z-index: 2;
}
.section-hero img {
  -ms-flex-item-align: start;
      align-self: start;
}
.section-hero .heading {
  --margin-bottom-mq-large:0;--margin-bottom-mq-medium:0;--margin-bottom-mq-small:0;--margin-bottom-mq-extra-small:0;
}
.section-hero .content {
  --margin-top-mq-large: 1rem;
  --padding-top-mq-large:1rem;--padding-top-mq-medium:1rem;--padding-top-mq-small:1rem;--padding-top-mq-extra-small:1rem;
}
.section-hero .content.cta-container {
  --padding-top-mq-large: unset;
  --margin-bottom-mq-large:2rem;--margin-bottom-mq-medium:2rem;--margin-bottom-mq-small:2rem;--margin-bottom-mq-extra-small:2rem;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
}
@media (max-width: 1024px) {
  .section-hero .content.cta-container {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-hero .content.cta-container {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .section-hero .content.cta-container {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
.section-hero .hero-logo {
  --margin-bottom-mq-large:2rem;--margin-bottom-mq-medium:2rem;--margin-bottom-mq-small:2rem;--margin-bottom-mq-extra-small:2rem;
  max-width: 15vw;
  min-width: 200px;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
}
@media (max-width: 1024px) {
  .section-hero .hero-logo {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-hero .hero-logo {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .section-hero .hero-logo {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
.section-hero.hero-freeform .content span {
  --font-size-mq-large:12px;--font-size-mq-medium:12px;--font-size-mq-small:12px;--font-size-mq-extra-small:12px;
}
.section-hero.hero-left {
  --padding-bottom-mq-large:25px;--padding-bottom-mq-medium:25px;--padding-bottom-mq-small:25px;--padding-bottom-mq-extra-small:25px;
  --padding-top-mq-large:40px;--padding-top-mq-medium:40px;--padding-top-mq-small:40px;--padding-top-mq-extra-small:40px;
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  .section-hero.hero-left {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-hero.hero-left {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .section-hero.hero-left {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
.section-hero.hero-left .hero-logo {
  --margin-bottom-mq-large:2rem;--margin-bottom-mq-medium:2rem;--margin-bottom-mq-small:2rem;--margin-bottom-mq-extra-small:2rem;
}
.section-hero.hero-left .hero-content {
  --max-width-mq-large:675px;--max-width-mq-medium:675px;--max-width-mq-small:675px;--max-width-mq-extra-small:675px;
  --padding-left-mq-large:20px;--padding-left-mq-medium:20px;--padding-left-mq-small:20px;--padding-left-mq-extra-small:20px;
  --padding-right-mq-large:20px;--padding-right-mq-medium:20px;--padding-right-mq-small:20px;--padding-right-mq-extra-small:20px;
  --padding-top-mq-large:16px;--padding-top-mq-medium:16px;--padding-top-mq-small:16px;--padding-top-mq-extra-small:16px;
  width: var(--width-mq-large);
  max-width: var(--max-width-mq-large);
  min-width: var(--min-width-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1024px) {
  .section-hero.hero-left .hero-content {
    width: var(--width-mq-medium);
    max-width: var(--max-width-mq-medium);
    min-width: var(--min-width-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-hero.hero-left .hero-content {
    width: var(--width-mq-small);
    max-width: var(--max-width-mq-small);
    min-width: var(--min-width-mq-small);
  }
}
@media (max-width: 416px) {
  .section-hero.hero-left .hero-content {
    width: var(--width-mq-extra-small);
    max-width: var(--max-width-mq-extra-small);
    min-width: var(--min-width-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .section-hero.hero-left .hero-content {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-hero.hero-left .hero-content {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .section-hero.hero-left .hero-content {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
.section-hero.hero-left .hero-content .heading {
  --margin-bottom-mq-large:1rem;--margin-bottom-mq-medium:1rem;--margin-bottom-mq-small:1rem;--margin-bottom-mq-extra-small:1rem;
}
.section-hero.hero-left .hero-content .hero-subhead {
  margin-bottom: 1rem;
}
.section-hero.hero-left .hero-content .hero-left-cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.section-hero.hero-left .hero-content .hero-left-cta :first-child {
  --margin-bottom-mq-large:1rem;--margin-bottom-mq-medium:1rem;--margin-bottom-mq-small:1rem;--margin-bottom-mq-extra-small:1rem;
}
.section-hero.hero-left .hero-content .hero-left-cta :not(:first-child) {
  --margin-top-mq-large:1rem;--margin-top-mq-medium:1rem;--margin-top-mq-small:1rem;--margin-top-mq-extra-small:1rem;
}
.section-hero.hero-left .hero-content .hero-left-cta :last-child {
  margin-bottom: 1rem;
}
.section-hero.hero-left .hero-content .hero-left-cta button {
  -ms-flex-item-align: center;
      align-self: center;
}
.section-hero.hero-left .hero-content .hero-left-cta button.primary {
  --margin-top-mq-large:1rem;--margin-top-mq-medium:1rem;--margin-top-mq-small:0rem;--margin-top-mq-extra-small:0rem;
  --margin-bottom-mq-large:0rem;--margin-bottom-mq-medium:0rem;--margin-bottom-mq-small:0rem;--margin-bottom-mq-extra-small:0rem;
  -ms-flex-item-align: center;
      align-self: center;
}
.section-hero.hero-left .hero-content img.hero-logo,
.section-hero.hero-left .hero-content img.hero-cta-logo {
  display: block;
}
@media (max-width: 1024px) {
  .section-hero.hero-left {
    padding-top: 50px;
  }
}
@media (orientation: portrait) and (max-width: 1024px) {
  .section-hero.hero-left {
    padding-top: 140px;
  }
}
@media (max-width: 1024px) {
  .section-hero {
    padding-top: 100px;
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-hero {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media only screen and (min-width: 428px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 3) {
  .section-hero {
    background-image: var(--background-mq-extra-small);
  }
}
.section-hero .hero-subhead {
  --margin-top-mq-large:0;--margin-top-mq-medium:0;--margin-top-mq-small:0;--margin-top-mq-extra-small:0;
  --margin-bottom-mq-large:1.5rem;--margin-bottom-mq-medium:1.5rem;--margin-bottom-mq-small:1.5rem;--margin-bottom-mq-extra-small:1.5rem;
}
.section-hero .hero-subhead span {
  display: var(--display-mq-large, block);
  line-height: 1.1;
}
@media (max-width: 1024px) {
  .section-hero .hero-subhead span {
    display: var(--display-mq-medium, block);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .section-hero .hero-subhead span {
    display: var(--display-mq-small, block);
  }
}
@media (max-width: 416px) {
  .section-hero .hero-subhead span {
    display: var(--display-mq-extra-small, block);
  }
}
p.section-hero .hero-subhead {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
}
.section-hero .heading:not(.hero-subhead) + .hero-subhead {
  --margin-top-mq-large:1.5rem;--margin-top-mq-medium:1.5rem;--margin-top-mq-small:1.5rem;--margin-top-mq-extra-small:1.5rem;
}
.hero-centered {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  --padding-left-mq-large:150px;--padding-left-mq-medium:70px;--padding-left-mq-small:70px;--padding-left-mq-extra-small:25px;
  --padding-right-mq-large:150px;--padding-right-mq-medium:70px;--padding-right-mq-small:70px;--padding-right-mq-extra-small:25px;
}
.hero-centered h1,
.hero-centered h2,
.hero-centered h3,
.hero-centered h4,
.hero-centered h5,
.hero-centered h6 {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  --margin-bottom-mq-large:2rem;--margin-bottom-mq-medium:2rem;--margin-bottom-mq-small:2rem;--margin-bottom-mq-extra-small:2rem;
}
.hero-centered img {
  -ms-flex-item-align: center;
      align-self: center;
}
.hero-centered button.primary {
  --margin-top-mq-large:1.5rem;--margin-top-mq-medium:1.5rem;--margin-top-mq-small:1.5rem;--margin-top-mq-extra-small:1.5rem;
  --margin-bottom-mq-large:1rem;--margin-bottom-mq-medium:1rem;--margin-bottom-mq-small:1rem;--margin-bottom-mq-extra-small:1rem;
  -ms-flex-item-align: center;
      align-self: center;
}
.hero-bundle .content,
.hero-ufc .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  --width-mq-large:600px;--width-mq-medium:600px;--width-mq-small:100%;--width-mq-extra-small:100%;
  --flex-direction-mq-large:row;--flex-direction-mq-medium:row;--flex-direction-mq-small:column;--flex-direction-mq-extra-small:column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: var(--flex-direction-mq-large);
          flex-direction: var(--flex-direction-mq-large);
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .hero-bundle .content,
  .hero-ufc .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: var(--flex-direction-mq-small);
            flex-direction: var(--flex-direction-mq-small);
    --padding-top-mq-extra-small: 2px;
  }
}
.hero-bundle .content .content,
.hero-ufc .content .content {
  --padding-right-mq-large:21px;--padding-right-mq-medium:21px;--padding-right-mq-small:unset;--padding-right-mq-extra-small:unset;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .hero-bundle .content .content,
  .hero-ufc .content .content {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .hero-bundle .content .content button,
  .hero-ufc .content .content button {
    -ms-flex-item-align: center;
        align-self: center;
  }
}
.hero-bundle .content .content p,
.hero-ufc .content .content p {
  --text-align-mq-small:center;--text-align-mq-extra-small:center;
  --font-size-mq-large:14px;--font-size-mq-medium:14px;--font-size-mq-small:14px;--font-size-mq-extra-small:14px;
  --max-width-mq-large:260px;--max-width-mq-medium:260px;--max-width-mq-small:90%;--max-width-mq-extra-small:90%;
}
.hero-bundle .content .content:nth-child(n + 2),
.hero-ufc .content .content:nth-child(n + 2) {
  --padding-top-mq-small:3em;--padding-top-mq-extra-small:3em;
  --padding-left-mq-large:21px;--padding-left-mq-medium:21px;--padding-left-mq-small:unset;--padding-left-mq-extra-small:unset;
  --margin-top-mq-small:3em;--margin-top-mq-extra-small:3em;
  --margin-left-mq-small:unset;--margin-left-mq-extra-small:unset;
  padding-left: var(--padding-left-mq-large);
  margin-left: var(--margin-left-mq-large);
  border-left: 1px solid #fff;
  -o-border-image: linear-gradient(transparent, #fff, transparent) 1 100%;
     border-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(#fff), to(transparent)) 1 100%;
     border-image: linear-gradient(transparent, #fff, transparent) 1 100%;
  border-right: 0;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .hero-bundle .content .content:nth-child(n + 2),
  .hero-ufc .content .content:nth-child(n + 2) {
    --padding-top-mq-small:0;--padding-top-mq-extra-small:0;
    --padding-left-mq-small:unset;--padding-left-mq-extra-small:unset;
    margin-top: 10px;
    padding-top: var(--padding-top-mq-small);
    padding-left: var(--padding-left-mq-small);
    border-left: 0;
  }
  .hero-bundle .content .content:nth-child(n + 2)::before,
  .hero-ufc .content .content:nth-child(n + 2)::before {
    content: '';
    margin: 30px auto 0 auto;
    width: 90%;
    background-color: #787878;
    border-top: 1px solid #fff;
    -o-border-image: unset;
       border-image: unset;
    opacity: 0.6;
  }
}
.hero-bundle > p:last-of-type,
.hero-ufc > p:last-of-type {
  --font-size-mq-large:10px;--font-size-mq-medium:10px;--font-size-mq-small:10px;--font-size-mq-extra-small:10px;
  margin-bottom: 80px;
}
.hero-bundle h1,
.hero-ufc h1 {
  --font-size-mq-large:60px;--font-size-mq-medium:60px;--font-size-mq-small:45px;--font-size-mq-extra-small:45px;
  --text-align-mq-small:center;--text-align-mq-extra-small:center;
  --width-mq-large:700px;--width-mq-medium:700px;--width-mq-small:80%;--width-mq-extra-small:80%;
  margin-top: 150px;
}
.hero-bundle h3,
.hero-ufc h3 {
  --letter-spacing: 1.68px;
  --font-weight: 500;
  --font-size-mq-large:32px;--font-size-mq-medium:32px;--font-size-mq-small:32px;--font-size-mq-extra-small:32px;
  --text-align-mq-small:center;--text-align-mq-extra-small:center;
}
.hero-bundle h4,
.hero-ufc h4 {
  --font-size-mq-medium:28px;--font-size-mq-small:22px;--font-size-mq-extra-small:22px;
  --text-align-mq-small:center;--text-align-mq-extra-small:center;
}
.hero-ufc .cta-container {
  --color-text: #fab200;
}
.hero-dual .hero-logo {
  --margin-bottom-mq-large:1rem;--margin-bottom-mq-medium:1rem;--margin-bottom-mq-small:1rem;--margin-bottom-mq-extra-small:1rem;
}
.hero-dual .cta-dual {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  --width-mq-large:600px;--width-mq-medium:100%;--width-mq-small:100%;--width-mq-extra-small:100%;
  --padding-left-mq-large:0rem;--padding-left-mq-medium:1rem;--padding-left-mq-small:1rem;--padding-left-mq-extra-small:1rem;
  --padding-right-mq-large:0rem;--padding-right-mq-medium:1rem;--padding-right-mq-small:1rem;--padding-right-mq-extra-small:1rem;
}
@media (min-width: 1025px) {
  .hero-dual .cta-dual {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: var(--flex-direction-mq-large);
            flex-direction: var(--flex-direction-mq-large);
  }
}
.hero-dual .cta-dual .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  --padding-right-mq-large:21px;--padding-right-mq-medium:0;
}
@media (min-width: 1025px) {
  .hero-dual .cta-dual .content {
    text-align: left;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.hero-dual .cta-dual .content button {
  -ms-flex-item-align: center;
      align-self: center;
}
@media (min-width: 1025px) {
  .hero-dual .cta-dual .content button {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
}
.hero-dual .cta-dual .content h1,
.hero-dual .cta-dual .content h2,
.hero-dual .cta-dual .content h3,
.hero-dual .cta-dual .content h4,
.hero-dual .cta-dual .content h5,
.hero-dual .cta-dual .content h6 {
  --letter-spacing-mq-large:1.68px;--letter-spacing-mq-medium:1.68px;--letter-spacing-mq-small:1.68px;--letter-spacing-mq-extra-small:1.68px;
  --line-height-mq-large:normal;--line-height-mq-medium:normal;--line-height-mq-small:normal;--line-height-mq-extra-small:normal;
}
.hero-dual .cta-dual .content button {
  --margin-top-mq-large:1rem;--margin-top-mq-medium:1rem;--margin-top-mq-small:1rem;--margin-top-mq-extra-small:1rem;
}
.hero-dual .cta-dual .content p {
  display: inline-block;
  --text-align-mq-large:left;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  --font-size-mq-large:14px;--font-size-mq-medium:14px;--font-size-mq-small:14px;--font-size-mq-extra-small:14px;
  --max-width-mq-large:260px;--max-width-mq-medium:90%;--max-width-mq-small:90%;--max-width-mq-extra-small:90%;
}
.hero-dual .cta-dual .content p button {
  display: inline;
  padding: unset;
}
.hero-dual .cta-dual .cta-dual-left {
  --padding-top-mq-medium:0rem;--padding-top-mq-small:0rem;--padding-top-mq-extra-small:0rem;
  --min-width-mq-large:295px;--min-width-mq-medium:295px;--min-width-mq-small:295px;--min-width-mq-extra-small:295px;
  -ms-flex-preferred-size: 49%;
      flex-basis: 49%;
}
.hero-dual .cta-dual .cta-dual-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  --padding-top-mq-medium:0;--padding-top-mq-small:0;--padding-top-mq-extra-small:0;
  --padding-left-mq-large:21px;--padding-left-mq-medium:0px;--padding-left-mq-small:0px;--padding-left-mq-extra-small:0px;
  --margin-top-mq-medium:0px;--margin-top-mq-small:0px;--margin-top-mq-extra-small:0px;
  border-left: 0;
  border-right: 0;
  -o-border-image: linear-gradient(transparent, #fff, transparent) 1 100%;
     border-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(#fff), to(transparent)) 1 100%;
     border-image: linear-gradient(transparent, #fff, transparent) 1 100%;
}
.hero-dual .cta-dual .cta-dual-right::before {
  content: '';
  margin: 30px auto 15px auto;
  width: 90%;
  background-color: #787878;
  border-top: 1px solid #fff;
  -o-border-image: linear-gradient(90deg, transparent, #fff, transparent) 100% 1;
     border-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#fff), to(transparent)) 100% 1;
     border-image: linear-gradient(90deg, transparent, #fff, transparent) 100% 1;
  opacity: 0.6;
}
@media (min-width: 1025px) {
  .hero-dual .cta-dual .cta-dual-right {
    border-left: 1px solid #fff;
  }
  .hero-dual .cta-dual .cta-dual-right::before {
    display: none;
  }
}
.hero-dual img {
  -ms-flex-item-align: unset;
      align-self: unset;
}
.hero-dual h1,
.hero-dual h2,
.hero-dual h3,
.hero-dual h4,
.hero-dual h5,
.hero-dual h6 {
  --text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
}
.hero-dual h1 {
  --font-size-mq-large:60px;--font-size-mq-medium:60px;--font-size-mq-small:45px;--font-size-mq-extra-small:40px;
  --width-mq-large:700px;--width-mq-medium:80%;--width-mq-small:80%;--width-mq-extra-small:100%;
  --margin-top-mq-large:150px;--margin-top-mq-medium:0;--margin-top-mq-small:0;--margin-top-mq-extra-small:0;
}
@media (min-width: 1025px) {
  .hero-dual h1 {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
}
.hero-dual h3 {
  --letter-spacing: 1.68px;
  --font-weight: 500;
  --font-size-mq-large:32px;--font-size-mq-medium:32px;--font-size-mq-small:32px;--font-size-mq-extra-small:32px;
}
.hero-dual h4 {
  --font-size-mq-medium:28px;--font-size-mq-small:22px;--font-size-mq-extra-small:22px;
}
.video-background {
  height: 100%;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
@media (max-width: 1024px) {
  .video-background {
    display: none;
  }
}
.logo-badge {
  --padding-left-mq-large: 25px;
  left: var(--padding-left-mq-large);
  height: 25px;
  position: absolute;
  top: 25px;
  width: auto;
}
.hero-caret {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
}
.hero-caret img {
  height: 30px;
  width: 30px;
}
.legal-copy {
  --text-align-mq-large:left;--text-align-mq-medium:left;--text-align-mq-small:left;--text-align-mq-extra-small:left;
  --margin-bottom-mq-large:60px;--margin-bottom-mq-medium:60px;--margin-bottom-mq-small:60px;--margin-bottom-mq-extra-small:60px;
  --font-size-mq-large:10px;--font-size-mq-medium:10px;--font-size-mq-small:10px;--font-size-mq-extra-small:10px;
  --line-height-mq-large:18px;--line-height-mq-medium:18px;--line-height-mq-small:18px;--line-height-mq-extra-small:18px;
  --color-text: rgba(255, 255, 255, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
  opacity: 0.7;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  width: var(--width-mq-large);
  max-width: var(--max-width-mq-large);
  min-width: var(--min-width-mq-large);
}
@media (max-width: 1024px) {
  .legal-copy {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .legal-copy {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .legal-copy {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .legal-copy {
    width: var(--width-mq-medium);
    max-width: var(--max-width-mq-medium);
    min-width: var(--min-width-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .legal-copy {
    width: var(--width-mq-small);
    max-width: var(--max-width-mq-small);
    min-width: var(--min-width-mq-small);
  }
}
@media (max-width: 416px) {
  .legal-copy {
    width: var(--width-mq-extra-small);
    max-width: var(--max-width-mq-extra-small);
    min-width: var(--min-width-mq-extra-small);
  }
}
@media (min-width: 1025px) {
  .legal-copy {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.hero-bundle .legal-copy {
  --width-mq-large:700px;--width-mq-medium:700px;--width-mq-small:80%;--width-mq-extra-small:80%;
}
.hero-centered .legal-copy {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
}
.hero-left .legal-copy {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  --margin-bottom-mq-large:0px;--margin-bottom-mq-medium:0px;--margin-bottom-mq-small:0px;--margin-bottom-mq-extra-small:0px;
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  .hero-left .legal-copy {
    text-align: var(--text-align-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .hero-left .legal-copy {
    text-align: var(--text-align-mq-small);
  }
}
@media (max-width: 416px) {
  .hero-left .legal-copy {
    text-align: var(--text-align-mq-extra-small);
  }
}
.hero-dual .legal-copy {
  --width-mq-large:700px;--width-mq-medium:700px;--width-mq-small:80%;--width-mq-extra-small:80%;
  --font-size-mq-large:10px;--font-size-mq-medium:10px;--font-size-mq-small:10px;--font-size-mq-extra-small:10px;
  --text-align-mq-large:left;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  .hero-dual .legal-copy {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .hero-dual .legal-copy {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .hero-dual .legal-copy {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
.legal-copy a {
  --text-decoration: underline;
  --color-link: rgba(76, 153, 230, 0.6);
  --color-link-active: rgba(0, 102, 204, 0.6);
  -webkit-text-decoration: var(--text-decoration);
          text-decoration: var(--text-decoration);
}
.legal-copy span {
  --color-background: initial;
}
.image-with-label {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  --margin-bottom-mq-small:1rem;--margin-bottom-mq-extra-small:1rem;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
}
@media (max-width: 1024px) {
  .image-with-label {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .image-with-label {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .image-with-label {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
.image-with-label img {
  margin-bottom: 10px;
}
.grid .image-with-label {
  --color-text: #90959e;
  --font-stack: var(--font-stack-playoff);
}
.grid.side-align.side-by-side {
  --padding-top-mq-large:40px;--padding-top-mq-medium:40px;--padding-top-mq-small:40px;--padding-top-mq-extra-small:40px;
  --padding-right-mq-large:40px;--padding-right-mq-medium:40px;--padding-right-mq-small:40px;--padding-right-mq-extra-small:40px;
  --padding-bottom-mq-large:40px;--padding-bottom-mq-medium:40px;--padding-bottom-mq-small:40px;--padding-bottom-mq-extra-small:40px;
  --padding-left-mq-large:40px;--padding-left-mq-medium:40px;--padding-left-mq-small:40px;--padding-left-mq-extra-small:40px;
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
}
@media (max-width: 1024px) {
  .grid.side-align.side-by-side {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .grid.side-align.side-by-side {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .grid.side-align.side-by-side {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .grid.side-align.side-by-side {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .grid.side-align.side-by-side {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .grid.side-align.side-by-side {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
.grid.side-align.side-by-side > :first-child {
  --margin-right-mq-large:2rem;--margin-right-mq-medium:auto;--margin-right-mq-small:auto;--margin-right-mq-extra-small:auto;
  --margin-left-mq-large:auto;--margin-left-mq-medium:auto;--margin-left-mq-small:auto;--margin-left-mq-extra-small:auto;
}
.grid.side-align.side-by-side:nth-of-type(odd) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.grid.side-align.side-by-side:nth-of-type(odd) > :first-child {
  --margin-left-mq-large:2rem;--margin-left-mq-medium:auto;--margin-left-mq-small:auto;--margin-left-mq-extra-small:auto;
  --margin-right-mq-large:auto;--margin-right-mq-medium:auto;--margin-right-mq-small:auto;--margin-right-mq-extra-small:auto;
}
.grid.side-align .grid-item {
  --margin-top-mq-large:0;
  --margin-right-mq-large:auto;--margin-right-mq-medium:auto;--margin-right-mq-small:auto;--margin-right-mq-extra-small:auto;
  --margin-bottom-mq-large:0;
  --margin-left-mq-large:auto;--margin-left-mq-medium:auto;--margin-left-mq-small:auto;--margin-left-mq-extra-small:auto;
  --max-width-mq-large: 536px;
  max-width: var(--max-width-mq-large);
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
}
@media (max-width: 1024px) {
  .grid.side-align .grid-item {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .grid.side-align .grid-item {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .grid.side-align .grid-item {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
.grid.side-align .grid-item.side-align-image-container img {
  --max-width-mq-large: 100%;
  max-width: var(--max-width-mq-large);
}
.grid.side-align .grid-item.side-align-content-container {
  --text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  --justify-content-mq-large: center;
  -webkit-box-pack: var(--justify-content-mq-large);
      -ms-flex-pack: var(--justify-content-mq-large);
          justify-content: var(--justify-content-mq-large);
}
.grid.side-align .grid-item.side-align-content-container h1,
.grid.side-align .grid-item.side-align-content-container h2,
.grid.side-align .grid-item.side-align-content-container h3,
.grid.side-align .grid-item.side-align-content-container h4,
.grid.side-align .grid-item.side-align-content-container h5,
.grid.side-align .grid-item.side-align-content-container h6 {
  --line-height-mq-large:normal;--line-height-mq-medium:normal;--line-height-mq-small:normal;--line-height-mq-extra-small:normal;
  --letter-spacing-mq-large:1.4px;--letter-spacing-mq-medium:1.4px;--letter-spacing-mq-small:1.4px;--letter-spacing-mq-extra-small:1.4px;
  --font-large-1: 40px;
  --font-large: 30px;
  --font-size-mq-large:var(--font-large-1);--font-size-mq-medium:var(--font-large);--font-size-mq-small:var(--font-large);--font-size-mq-extra-small:var(--font-large);
}
.grid.side-align .grid-item.side-align-content-container p {
  --color-text: #6c6d6f;
  --font-stack: var(--font-stack-benton);
  --font-small-3: 16px;
  --font-small-1: 14px;
  --font-size-mq-large:var(--font-small-3);--font-size-mq-medium:var(--font-small-3);--font-size-mq-small:var(--font-small-3);--font-size-mq-extra-small:var(--font-small-1);
  --text-align-mq-large:left;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  line-height: 24px;
}
.grid.side-align .grid-item.side-align-content-container p.legal-copy {
  line-height: 1.2;
  --font-size-mq-large:12px;--font-size-mq-medium:12px;--font-size-mq-small:12px;--font-size-mq-extra-small:12px;
  --color-text: rgba(51, 51, 51, 0.4);
}
.grid.side-align .grid-item.side-align-content-container hr {
  --margin-top-mq-large: 0;
  --margin-right-mq-large: 0;
  --margin-bottom-mq-large: 12px;
  --margin-left-mq-large: 0;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
}
@media (max-width: 1024px) {
  .grid.side-align .grid-item.side-align-content-container hr {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .grid.side-align .grid-item.side-align-content-container hr {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .grid.side-align .grid-item.side-align-content-container hr {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .grid.side-align .grid-item.side-align-content-container hr {
    display: none;
  }
}
@media (max-width: 1024px) {
  .grid.side-align .grid-item.side-align-content-container {
    --max-width-mq-medium: 536px;
    --text-align-mq-medium: center;
    --margin-top-mq-large: 10px;
    --margin-right-mq-large: auto;
    --margin-bottom-mq-large: 0;
    --margin-left-mq-large: auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: var(--text-align-mq-medium);
    max-width: var(--max-width-mq-medium);
    margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  }
}
@media (max-width: 1024px) and (max-width: 1024px) {
  .grid.side-align .grid-item.side-align-content-container {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 1024px) and (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .grid.side-align .grid-item.side-align-content-container {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 1024px) and (max-width: 416px) {
  .grid.side-align .grid-item.side-align-content-container {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
.grid.side-align.full-bleed {
  position: relative;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-left: 20px;
}
.grid.side-align.full-bleed .grid-item.side-align-image-container {
  position: absolute;
  max-width: 100%;
  height: unset;
}
.grid.side-align.full-bleed .grid-item.side-align-content-container {
  z-index: 1;
  margin-left: 20px;
}
@media (min-width: 1025px) {
  .grid.side-align.full-bleed .grid-item.side-align-content-container {
    display: none;
  }
}
picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
picture img {
  max-width: 100%;
}
img {
  height: auto;
  width: auto;
}
p {
  background-color: var(--color-background);
  color: var(--color-text);
  display: var(--display-mq-large, block);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
  width: var(--width-mq-large);
  max-width: var(--max-width-mq-large);
  min-width: var(--min-width-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  p {
    display: var(--display-mq-medium, block);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  p {
    display: var(--display-mq-small, block);
  }
}
@media (max-width: 416px) {
  p {
    display: var(--display-mq-extra-small, block);
  }
}
@media (max-width: 1024px) {
  p {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  p {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  p {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  p {
    width: var(--width-mq-medium);
    max-width: var(--max-width-mq-medium);
    min-width: var(--min-width-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  p {
    width: var(--width-mq-small);
    max-width: var(--max-width-mq-small);
    min-width: var(--min-width-mq-small);
  }
}
@media (max-width: 416px) {
  p {
    width: var(--width-mq-extra-small);
    max-width: var(--max-width-mq-extra-small);
    min-width: var(--min-width-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  p {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  p {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  p {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
section.carousel h1,
section.carousel h2,
section.carousel h3,
section.carousel h4,
section.carousel h5,
section.carousel h6 {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  section.carousel h1,
  section.carousel h2,
  section.carousel h3,
  section.carousel h4,
  section.carousel h5,
  section.carousel h6 {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.carousel h1,
  section.carousel h2,
  section.carousel h3,
  section.carousel h4,
  section.carousel h5,
  section.carousel h6 {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  section.carousel h1,
  section.carousel h2,
  section.carousel h3,
  section.carousel h4,
  section.carousel h5,
  section.carousel h6 {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
section.carousel .carousel-wrapper {
  --font-stack: var(--font-stack-benton);
  overflow: hidden;
  position: relative;
  display: var(--display-mq-large, block);
}
@media (max-width: 1024px) {
  section.carousel .carousel-wrapper {
    display: var(--display-mq-medium, block);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.carousel .carousel-wrapper {
    display: var(--display-mq-small, block);
  }
}
@media (max-width: 416px) {
  section.carousel .carousel-wrapper {
    display: var(--display-mq-extra-small, block);
  }
}
section.carousel .carousel-wrapper button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 40%;
}
section.carousel .carousel-wrapper .carousel {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  background: transparent;
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper.slide--2x3 img {
  border-radius: 10px;
}
@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper.slide--1x1 img,
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper.slide--16x9 img,
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper.slide--5x2 img,
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper.slide--2x3 img {
    height: 100%;
    width: 100%;
  }
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .carousel-slide {
  --color-text: #000000;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: var(--color-text);
  pointer-events: var(--pointer-events, auto);
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .carousel-slide:hover .slide-image {
  -webkit-box-shadow: 0 0 11px 0 #000000;
          box-shadow: 0 0 11px 0 #000000;
  -webkit-transform: scale(1);
          transform: scale(1);
}
@media (prefers-reduced-motion) {
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .carousel-slide:hover .slide-image {
    -webkit-transform: unset;
            transform: unset;
  }
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .carousel-slide:hover .media-pill {
  bottom: 6%;
  left: 5.1%;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .carousel-slide .slide-image {
  display: block;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .carousel-slide .media-pill {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  bottom: 10%;
  left: 9%;
  border-radius: 8px;
  font-size: 11px;
  font-weight: 600;
  height: 20px;
  line-height: 1.18;
  padding: 0 10px;
  letter-spacing: 1.7px;
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .carousel-slide .media-pill.live {
  line-height: unset;
  letter-spacing: 0.8px;
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .carousel-slide .media-pill.live::before {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  content: "";
  display: inline-block;
  background: #c00;
  margin-right: 4px;
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .media-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .media-data span {
  --color-background: transparent;
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .media-data .carousel-slide__title {
  --color-text: #013265;
  --font-size-mq-large:12px;--font-size-mq-medium:12px;--font-size-mq-small:12px;--font-size-mq-extra-small:12px;
  --font-weight: bold;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
  color: var(--color-text);
}
@media (max-width: 1024px) {
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .media-data .carousel-slide__title {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .media-data .carousel-slide__title {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .media-data .carousel-slide__title {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .media-data .carousel-slide__subtitle {
  --font-size-mq-large:11px;--font-size-mq-medium:11px;--font-size-mq-small:11px;--font-size-mq-extra-small:11px;
  --color-text: rgba(1, 50, 101, 0.7);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
  letter-spacing: 1.2px;
  margin: 2px 0;
  color: var(--color-text);
}
@media (max-width: 1024px) {
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .media-data .carousel-slide__subtitle {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .media-data .carousel-slide__subtitle {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  section.carousel .carousel-wrapper .carousel .carousel-slide-wrapper .media-data .carousel-slide__subtitle {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
section.carousel .carousel-wrapper .arrow {
  width: 40px;
  height: 53px;
  border-radius: 0;
  fill: #ffffff;
  background: rgba(55, 55, 55, 0.3);
  border-radius: 4px;
}
section.carousel .carousel-wrapper .arrow:hover {
  background: #373737;
}
section.carousel .carousel-wrapper .arrow svg {
  width: 40px;
  height: 40px;
}
section.carousel .carousel-wrapper .arrow.arrow--prev {
  left: 0;
}
section.carousel .carousel-wrapper .arrow.arrow--prev svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-left: 0px;
  margin-bottom: -3px;
}
section.carousel .carousel-wrapper .arrow.arrow--next {
  right: 0;
}
section.carousel .carousel-wrapper .arrow.arrow--next svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-right: 0px;
  margin-top: 3px;
}
@media (max-width: 1024px) {
  section.carousel .carousel-wrapper .arrow {
    width: 30px;
    height: 40px;
  }
  section.carousel .carousel-wrapper .arrow svg {
    width: 30px;
    height: 30px;
  }
}
.grid {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  display: var(--display-mq-large, flex);
}
@media (max-width: 1024px) {
  .grid {
    display: var(--display-mq-medium, flex);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .grid {
    display: var(--display-mq-small, flex);
  }
}
@media (max-width: 416px) {
  .grid {
    display: var(--display-mq-extra-small, flex);
  }
}
.grid.nowrap {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.section-grid {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
}
.grid-item {
  display: var(--display-mq-large, flex);
}
@media (max-width: 1024px) {
  .grid-item {
    display: var(--display-mq-medium, flex);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .grid-item {
    display: var(--display-mq-small, flex);
  }
}
@media (max-width: 416px) {
  .grid-item {
    display: var(--display-mq-extra-small, flex);
  }
}
footer {
  --color-link: #ffffff;
  --color-link-active: #0066cc;
  --color-text: #ffffff;
  --footer-desktop-width: 100%;
  --footer-desktop-max-width: 1440px;
  --footer-background-color: #ececec;
  --footer-background-image: none;
  --footer-top-background-color: #292928;
  --footer-linkset-header-color: #013265;
  --footer-linkset-font: #013265;
  --footer-bottom-border: 1px solid #d6dbe0;
  --footer-language-button-color: #013265;
  --font-stack: var(--font-stack-benton);
  background-color: var(--footer-background-color);
}
footer .footer-legal {
  --footer-gradient: linear-gradient(#013265, #000000);
  background-color: var(--footer-top-background-color);
  background-image: var(--footer-top-background-image);
  color: var(--color-text);
  --gradient-mq-large:var(--footer-gradient);--gradient-mq-medium:var(--footer-gradient);--gradient-mq-small:var(--footer-gradient);--gradient-mq-extra-small:var(--footer-gradient);
  /*
  // Note: safe to use with image backgrounds.
  // Image-based backgrounds generate inline and will cascade over the gradient.
  */
  background-image: var(--gradient-mq-large, none);
}
@media (max-width: 1024px) {
  footer .footer-legal {
    background-image: var(--gradient-mq-medium, var(--gradient-mq-large, none));
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-legal {
    background-image: var(--gradient-mq-small, var(--gradient-mq-medium, var(--gradient-mq-large, none)));
  }
}
@media (max-width: 416px) {
  footer .footer-legal {
    background-image: var(--gradient-mq-extra-small, var(--gradient-mq-small, var(--gradient-mq-medium, var(--gradient-mq-large, none))));
  }
}
footer .footer-legal p {
  --margin-top-mq-large: 0;
  --margin-right-mq-large: auto;
  --margin-bottom-mq-large: 0;
  --margin-left-mq-large: auto;
  --padding-top-mq-large:20px;--padding-top-mq-medium:20px;--padding-top-mq-small:20px;--padding-top-mq-extra-small:20px;
  --padding-right-mq-large:150px;--padding-right-mq-medium:70px;--padding-right-mq-small:20px;--padding-right-mq-extra-small:20px;
  --padding-bottom-mq-large:20px;--padding-bottom-mq-medium:20px;--padding-bottom-mq-small:20px;--padding-bottom-mq-extra-small:20px;
  --padding-left-mq-large:150px;--padding-left-mq-medium:70px;--padding-left-mq-small:20px;--padding-left-mq-extra-small:20px;
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  line-height: 1.5;
  width: var(--footer-desktop-width);
  max-width: var(--footer-desktop-max-width);
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  footer .footer-legal p {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-legal p {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  footer .footer-legal p {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  footer .footer-legal p {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-legal p {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  footer .footer-legal p {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  footer .footer-legal p {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-legal p {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  footer .footer-legal p {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
footer .footer-legal a {
  --text-decoration: underline;
  -webkit-text-decoration: var(--text-decoration);
          text-decoration: var(--text-decoration);
}
footer .footer-legal a:hover,
footer .footer-legal a:active {
  --text-decoration: none;
}
@media (max-width: 1024px) {
  footer .footer-legal span {
    display: initial;
  }
}
footer .footer-main {
  --margin-top-mq-large: 0;
  --margin-right-mq-large: auto;
  --margin-bottom-mq-large: 0;
  --margin-left-mq-large: auto;
  --padding-right-mq-large:150px;--padding-right-mq-medium:70px;--padding-right-mq-small:50px;--padding-right-mq-extra-small:50px;
  --padding-left-mq-large:150px;--padding-left-mq-medium:70px;--padding-left-mq-small:50px;--padding-left-mq-extra-small:50px;
  --padding-top-mq-large:2rem;--padding-top-mq-medium:40px;--padding-top-mq-small:20px;--padding-top-mq-extra-small:20px;
  width: 100%;
  max-width: var(--footer-desktop-max-width);
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  footer .footer-main {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-main {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  footer .footer-main {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  footer .footer-main {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-main {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  footer .footer-main {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
footer .footer-link-set {
  --color-background: transparent;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-item-align: start;
      align-self: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-link-set {
    margin: 0 0 2rem;
  }
}
footer .footer-link-set:nth-child(even) {
  --margin-right-mq-large:3rem;--margin-right-mq-medium:1rem;--margin-right-mq-small:0;--margin-right-mq-extra-small:0;
  --margin-left-mq-large:3rem;--margin-left-mq-medium:1rem;--margin-left-mq-small:0;--margin-left-mq-extra-small:0;
  --margin-bottom-mq-small:2rem;--margin-bottom-mq-extra-small:2rem;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
}
@media (max-width: 1024px) {
  footer .footer-link-set:nth-child(even) {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-link-set:nth-child(even) {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  footer .footer-link-set:nth-child(even) {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
footer .footer-link-set .heading {
  --font-size-mq-large: 25px;
  --color-text: #013265;
  font-size: var(--font-size-mq-large);
  color: var(--color-text);
  background: var(--color-background);
  text-transform: uppercase;
  letter-spacing: 0;
  margin: 0 0 1.25rem;
}
footer .footer-link-set ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
footer .footer-link-set ul.multi-col {
  -webkit-columns: 2 auto;
     -moz-columns: 2 auto;
          columns: 2 auto;
}
@media (max-width: 1024px) {
  footer .footer-link-set ul.multi-col {
    -webkit-columns: unset;
       -moz-columns: unset;
            columns: unset;
  }
}
footer .footer-link-set ul li {
  line-height: 42px;
}
footer .footer-link-set ul a {
  --color-link: #013265;
  --color-link-active: #0066cc;
  --font-stack: var(--font-stack-benton);
  --font-size-mq-large: 15px;
  --font-size-mq-medium: 14px;
  --font-size-mq-small: 14px;
  letter-spacing: 0.63px;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  footer .footer-link-set ul a {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-link-set ul a {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  footer .footer-link-set ul a {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
footer .footer-link-set ul a:link,
footer .footer-link-set ul a:visited {
  color: var(--color-link);
  text-decoration: none;
}
footer .footer-link-set ul a:hover,
footer .footer-link-set ul a:active {
  color: var(--color-link-active);
  text-decoration: underline;
}
footer .footer-bottom {
  --padding-top-mq-large:2rem;--padding-top-mq-medium:20px;--padding-top-mq-small:20px;--padding-top-mq-extra-small:20px;
  --padding-right-mq-large:150px;--padding-right-mq-medium:70px;--padding-right-mq-small:50px;--padding-right-mq-extra-small:50px;
  --padding-bottom-mq-large:55px;--padding-bottom-mq-medium:55px;--padding-bottom-mq-small:20px;--padding-bottom-mq-extra-small:20px;
  --padding-left-mq-large:150px;--padding-left-mq-medium:70px;--padding-left-mq-small:50px;--padding-left-mq-extra-small:50px;
  margin: 0 auto;
  width: 100%;
  max-width: var(--footer-desktop-max-width);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
footer .footer-bottom:before {
  content: "";
  border-bottom: var(--footer-bottom-border);
  margin-bottom: 1.25rem;
  display: block;
}
@media (max-width: 1024px) {
  footer .footer-bottom {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-bottom {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  footer .footer-bottom {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
footer .footer-bottom-set {
  color: var(--footer-language-button-color);
  font-family: var(--font-stack);
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-bottom-set {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-bottom-set > div {
    margin-bottom: 30px;
  }
}
footer .footer-bottom-set .brand-logo img {
  max-height: 31px;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-bottom-set .brand-logo img {
    width: 150px;
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-bottom-set .brand-logo img {
    width: 100px;
  }
}
footer .footer-bottom-set .language-toggle {
  padding: 0 30px;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-bottom-set .language-toggle {
    padding: 0;
  }
}
footer .footer-bottom-set .language-toggle-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: var(--font-stack);
  color: var(--footer-language-button-color);
  cursor: pointer;
  border: 2px solid var(--footer-language-button-color);
  border-radius: 2rem;
  letter-spacing: 0.3px;
  line-height: 1;
  padding: 1rem 1.5rem;
  outline: none;
  text-transform: uppercase;
}
footer .footer-bottom-set .language-toggle-btn .caret {
  color: currentColor;
  font-size: 3rem;
  height: 0px;
  margin: 0 -1rem 0 -0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1024px) {
  footer .footer-bottom-set .language-toggle-btn {
    padding: 11px 21px;
  }
}
footer .footer-bottom-set .footer-cta {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 50%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
footer .footer-bottom-set .footer-cta span {
  --padding-top-mq-large:0;--padding-top-mq-medium:0;--padding-top-mq-small:0;--padding-top-mq-extra-small:0;
  --padding-right-mq-large:0;--padding-right-mq-medium:0;--padding-right-mq-small:0;--padding-right-mq-extra-small:0;
  --padding-bottom-mq-large:0;--padding-bottom-mq-medium:0;--padding-bottom-mq-small:0;--padding-bottom-mq-extra-small:0;
  --padding-left-mq-large:0;--padding-left-mq-medium:0;--padding-left-mq-small:0;--padding-left-mq-extra-small:0;
  --color-text: var(--footer-language-button-color);
  --color-background: transparent;
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  footer .footer-bottom-set .footer-cta span {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-bottom-set .footer-cta span {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  footer .footer-bottom-set .footer-cta span {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
footer .footer-bottom-set .footer-cta * {
  margin-top: 10px;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  footer .footer-bottom-set .footer-cta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-left: unset;
    margin-top: 15px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
  }
  footer .footer-bottom-set .footer-cta > button,
  footer .footer-bottom-set .footer-cta > span {
    margin-bottom: 30px;
  }
  footer .footer-bottom-set .footer-cta * {
    margin-top: unset;
  }
}
footer .footer-bottom-set .footer-button {
  background-color: transparent;
}
footer .footer-bottom-set .footer-button.regular-link {
  font-family: var(--font-stack);
  border: 0;
  outline: none;
  padding: 0;
}
footer .footer-bottom-set .footer-button.regular-link:hover {
  color: var(--footer-link-hover-color);
  text-decoration: underline;
}
.footer-hidden-shelf {
  --color-text: #ffffff;
  --font-size-mq-large: 14px;
  --footer-hidden-shelf-background: #000000;
  --font-stack: var(--font-stack-benton);
  color: var(--color-text);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background-color: var(--footer-hidden-shelf-background);
  overflow: hidden;
  border-top: 1px solid #787878;
  opacity: 0;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  z-index: 2;
}
.footer-hidden-shelf.shelf-enter-done {
  opacity: 1;
  height: 160px;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}
.footer-hidden-shelf.shelf-enter-done .heading {
  opacity: 1;
}
.footer-hidden-shelf.shelf-enter-done button[data-lang='en'] {
  bottom: 5%;
  opacity: 1;
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}
.footer-hidden-shelf.shelf-enter-done button[data-lang='es'] {
  bottom: 10%;
  opacity: 1;
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}
.footer-hidden-shelf.shelf-enter-done .close-icon {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .footer-hidden-shelf.shelf-enter-done {
    min-height: 160px;
    height: auto;
  }
}
.footer-hidden-shelf.lang-en button[data-lang='en']:after,
.footer-hidden-shelf.lang-es button[data-lang='es']:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #ffffff;
}
.footer-hidden-shelf .close-icon {
  --font-size-mq-large: 22px;
  position: absolute;
  top: 0;
  right: 0;
  color: currentColor;
  font-size: var(--font-size-mq-large);
  padding: 25px 30px;
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear;
  cursor: pointer;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .footer-hidden-shelf .close-icon {
    padding: 10px 20px;
  }
}
.footer-hidden-shelf .heading {
  --color-text: #ffffff;
  --font-style: normal;
  --font-weight: normal;
  --margin-top-mq-large: 15px;
  --margin-right-mq-large: 0;
  --margin-bottom-mq-large: 15px;
  --margin-left-mq-large: 0;
  color: var(--color-text);
  font-family: var(--font-stack);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  text-transform: inherit;
  opacity: 0;
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
}
@media (max-width: 1024px) {
  .footer-hidden-shelf .heading {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .footer-hidden-shelf .heading {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .footer-hidden-shelf .heading {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
.footer-hidden-shelf button {
  --font-size-mq-large: 14px;
  --color-text: #787878;
  --font-style: normal;
  --font-weight: normal;
  -ms-flex-item-align: center;
      align-self: center;
  background: none;
  position: relative;
  bottom: -10%;
  color: var(--color-text);
  font-family: var(--font-stack);
  font-weight: var(--font-weight);
  font-style: var(--font-style);
  font-size: var(--font-size-mq-large);
  letter-spacing: 0.3px;
  line-height: 1.85;
  margin: 10px 0;
  padding: 0 20px;
  text-align: center;
  text-transform: capitalize;
  opacity: 0;
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
.footer-hidden-shelf button:hover {
  color: #ffffff;
  background: none;
}
.footer-hidden-shelf p {
  --font-size-mq-large: 16px;
  --padding-top-mq-large:15px;--padding-top-mq-medium:15px;--padding-top-mq-small:15px;--padding-top-mq-extra-small:25px;
  --padding-right-mq-large:50px;--padding-right-mq-medium:50px;--padding-right-mq-small:50px;
  --padding-bottom-mq-large:15px;--padding-bottom-mq-medium:15px;--padding-bottom-mq-small:15px;--padding-bottom-mq-extra-small:25px;
  --padding-left-mq-large:50px;--padding-left-mq-medium:50px;--padding-left-mq-small:50px;
  width: 85%;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  .footer-hidden-shelf p {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .footer-hidden-shelf p {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .footer-hidden-shelf p {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
@media (max-width: 416px) {
  .footer-hidden-shelf p {
    margin: 40px 0;
  }
}
nav.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: fixed;
  width: 100%;
  padding: 20px;
  background-color: transparent;
  font-family: var(--font-stack-benton);
  color: var(--color-copy);
  text-transform: uppercase;
  z-index: 3;
  top: 0;
}
nav.grid div.grid-item {
  height: 100%;
  margin: auto 0;
}
nav.grid img {
  max-width: 122px;
}
@media (max-width: 416px) {
  nav.grid img {
    max-width: 100px;
    max-height: 20px;
  }
}
nav.grid a {
  --text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-link);
}
nav.grid a:hover,
nav.grid a:active {
  --text-decoration: underline;
}
nav.grid button.tertiary {
  font-weight: bold;
}
nav.grid button.tertiary:hover,
nav.grid button.tertiary:active {
  --text-decoration: underline;
}
nav.grid.nav-sticky {
  /* ToDo:  Use style parser for borders when it is ready */
  border-bottom: 2px solid #fab200;
  background: -webkit-gradient(linear, left top, left bottom, from(#013265), to(#000000));
  background: linear-gradient(#013265, #000000);
}
span {
  display: var(--display-mq-large, inline);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
  color: var(--color-text);
  background-color: var(--color-background);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  span {
    display: var(--display-mq-medium, inline);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  span {
    display: var(--display-mq-small, inline);
  }
}
@media (max-width: 416px) {
  span {
    display: var(--display-mq-extra-small, inline);
  }
}
@media (max-width: 1024px) {
  span {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  span {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  span {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  span {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  span {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  span {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
.faq {
  --color-link: #0066cc;
  --padding-top-mq-large:0;--padding-top-mq-medium:0;--padding-top-mq-small:0;--padding-top-mq-extra-small:0;
  --padding-right-mq-large:20px;--padding-right-mq-medium:20px;--padding-right-mq-small:20px;--padding-right-mq-extra-small:20px;
  --padding-bottom-mq-large:0;--padding-bottom-mq-medium:0;--padding-bottom-mq-small:0;--padding-bottom-mq-extra-small:0;
  --padding-left-mq-large:20px;--padding-left-mq-medium:20px;--padding-left-mq-small:20px;--padding-left-mq-extra-small:20px;
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  .faq {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .faq {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .faq {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
.faq .faq-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 30px 0px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  --font-stack: var(--font-stack-playoff);
  --font-style: italic;
  --font-weight: bold;
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  --font-size-mq-large:30px;--font-size-mq-medium:30px;--font-size-mq-small:30px;--font-size-mq-extra-small:30px;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  .faq .faq-heading {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .faq .faq-heading {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .faq .faq-heading {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
.faq .faq-item-container .faq-item {
  width: 55vw;
  margin: auto;
  border-top: solid 3px #E8E8E8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  --padding-top-mq-large:5px;--padding-top-mq-medium:5px;--padding-top-mq-small:5px;--padding-top-mq-extra-small:5px;
  --padding-right-mq-large:0;--padding-right-mq-medium:0;--padding-right-mq-small:0;--padding-right-mq-extra-small:0;
  --padding-bottom-mq-large:5px;--padding-bottom-mq-medium:5px;--padding-bottom-mq-small:5px;--padding-bottom-mq-extra-small:5px;
  --padding-left-mq-large:0;--padding-left-mq-medium:0;--padding-left-mq-small:0;--padding-left-mq-extra-small:0;
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  .faq .faq-item-container .faq-item {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .faq .faq-item-container .faq-item {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .faq .faq-item-container .faq-item {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .faq .faq-item-container .faq-item {
    width: 100%;
  }
}
.faq .faq-item-container .faq-item a {
  text-decoration: underline;
}
.faq .faq-item-container .faq-item a:link,
.faq .faq-item-container .faq-item a:visited,
.faq .faq-item-container .faq-item a:hover,
.faq .faq-item-container .faq-item a:active {
  color: var(--color-link);
}
.faq .faq-item-container .faq-item button {
  width: 100%;
  background-color: inherit;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.faq .faq-item-container .faq-item button.no-outline {
  outline: none;
}
.faq .faq-item-container .faq-item button .toggle {
  --font-stack: var(--font-stack-playoff);
  --color-text: #fab200;
  padding: 3px 7px;
  font-family: var(--font-stack);
  height: 40px;
  width: 40px;
  border: solid 3px var(--color-text);
  border-radius: 50%;
  color: var(--color-text);
  line-height: 33px;
}
.faq .faq-item-container .faq-item button .toggle.closed::before {
  content: "+";
  font-size: 45px;
}
.faq .faq-item-container .faq-item button .toggle.open::before {
  content: "-";
  font-size: 45px;
  padding: 3px;
}
.faq .faq-item-container .faq-item button .faq-question {
  font-family: playoff, sans-serif, Roboto, Arial, sans-serif;
  font-weight: bold;
  font-style: italic;
  font-size: 1.5em;
  color: black;
  display: inline-block;
  border: none;
  padding: 20px 10px 20px 0;
  text-align: left;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .faq .faq-item-container .faq-item button .faq-question {
    padding: 0 10px 0 0;
  }
}
.faq .faq-item-container .faq-item button:focus {
  outline: none;
}
.faq .faq-item-container .faq-answer {
  --padding-top-mq-large:20px;--padding-top-mq-medium:20px;--padding-top-mq-small:20px;--padding-top-mq-extra-small:20px;
  --padding-right-mq-large:6px;--padding-right-mq-medium:6px;--padding-right-mq-small:6px;--padding-right-mq-extra-small:6px;
  --padding-bottom-mq-large:5px;--padding-bottom-mq-medium:5px;--padding-bottom-mq-small:5px;--padding-bottom-mq-extra-small:5px;
  --padding-left-mq-large:6px;--padding-left-mq-medium:6px;--padding-left-mq-small:6px;--padding-left-mq-extra-small:6px;
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  .faq .faq-item-container .faq-answer {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .faq .faq-item-container .faq-answer {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .faq .faq-item-container .faq-answer {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
.faq .faq-item-container .faq-answer p {
  line-height: 24px;
  letter-spacing: 1px;
  --color-text: #444444;
  --font-stack: var(--font-stack-benton);
  --font-weight: 500;
  --text-align-mq-large:left;--text-align-mq-medium:left;--text-align-mq-small:left;--text-align-mq-extra-small:left;
  --font-size-mq-large:16px;--font-size-mq-medium:16px;--font-size-mq-small:16px;--font-size-mq-extra-small:16px;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  .faq .faq-item-container .faq-answer p {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .faq .faq-item-container .faq-answer p {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .faq .faq-item-container .faq-answer p {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
.faq .faq-item-container .faq-answer p:nth-child(even) {
  margin-top: 16px;
}
.faq .faq-item-container .faq-answer.hidden {
  display: none;
}
.faq .faq-item-container .faq-answer.active {
  display: block;
}
.faq .view-more-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 55vw;
  margin: auto;
  border-top: 3px solid #E8E8E8;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .faq .view-more-container {
    width: 100%;
  }
}
.faq .view-more-container .view-more,
.faq .view-more-container .view-less {
  border: none;
  background-color: inherit;
  font-size: 20px;
  color: #90959e;
  padding: 20px 0px;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .faq .view-more-container .view-more,
  .faq .view-more-container .view-less {
    margin-right: 10px;
  }
}
#measure-layer {
  display: inline-block;
  position: absolute;
  visibility: hidden;
  z-index: -1;
}
#measure-layer .tab-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: auto;
}
#measure-layer .tab-panel p {
  --color-text: #ffffff;
  --text-align-mq-large: center;
  --text-align-mq-small: center;
  color: var(--color-text);
  text-align: var(--text-align-mq-large);
  letter-spacing: 1px;
  margin: 0 auto;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  #measure-layer .tab-panel p {
    text-align: var(--text-align-mq-small);
  }
}
#measure-layer .tab-panel .grid-item {
  --margin-top-mq-large: 50px;
  margin-top: var(--margin-top-mq-large);
}
#measure-layer .tab-panel .grid-item .lazy-picture img,
#measure-layer .tab-panel .grid-item .image-with-label img {
  height: 122px;
}
section.tab-module {
  --color-text: #ffffff;
  --padding-top-mq-large:40px;--padding-top-mq-medium:40px;--padding-top-mq-small:40px;--padding-top-mq-extra-small:40px;
  --padding-right-mq-large:30px;--padding-right-mq-medium:30px;--padding-right-mq-small:30px;--padding-right-mq-extra-small:30px;
  --padding-bottom-mq-large:40px;--padding-bottom-mq-medium:40px;--padding-bottom-mq-small:40px;--padding-bottom-mq-extra-small:40px;
  --padding-left-mq-large:30px;--padding-left-mq-medium:30px;--padding-left-mq-small:30px;--padding-left-mq-extra-small:30px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#013265), color-stop(#012f5e), to(#000000));
  background-image: linear-gradient(#013265, #012f5e, #000000);
  margin: 0 auto;
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  section.tab-module {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.tab-module {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  section.tab-module {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
section.tab-module h1,
section.tab-module h2,
section.tab-module h3,
section.tab-module h4,
section.tab-module h5,
section.tab-module h6 {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  --margin-top-mq-large:26px;--margin-top-mq-medium:26px;--margin-top-mq-small:26px;--margin-top-mq-extra-small:26px;
  --margin-right-mq-large:auto;--margin-right-mq-medium:auto;--margin-right-mq-small:auto;--margin-right-mq-extra-small:auto;
  --margin-bottom-mq-large:26px;--margin-bottom-mq-medium:26px;--margin-bottom-mq-small:26px;--margin-bottom-mq-extra-small:26px;
  --margin-left-mq-large:auto;--margin-left-mq-medium:auto;--margin-left-mq-small:auto;--margin-left-mq-extra-small:auto;
  color: var(--color-text);
}
section.tab-module p {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  margin-bottom: 26px;
  color: var(--color-text);
}
section.tab-module .tab-view {
  max-width: 1140px;
  margin: 0 auto;
  display: var(--display-mq-large, block);
}
@media (max-width: 1024px) {
  section.tab-module .tab-view {
    display: var(--display-mq-medium, block);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.tab-module .tab-view {
    display: var(--display-mq-small, block);
  }
}
@media (max-width: 416px) {
  section.tab-module .tab-view {
    display: var(--display-mq-extra-small, block);
  }
}
section.tab-module .tab-view .tab-header-list {
  margin: 10px 0px 40px 0;
  text-align: center;
}
section.tab-module .tab-view .tab-header-list .desktop-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 1280px;
  margin: 0 auto;
}
section.tab-module .tab-view .tab-header-list .desktop-tabs .nested-tab-captions {
  --color-text: #ffffff;
  display: inline-block;
  border: none;
  text-decoration: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: var(--color-text);
  padding: 5px 0px;
  margin: 0px 5px;
  border-top: 5px solid transparent;
  outline: 0;
  font-size: 18px;
}
section.tab-module .tab-view .tab-header-list .desktop-tabs .nested-tab-captions[data-active='true'],
section.tab-module .tab-view .tab-header-list .desktop-tabs .nested-tab-captions:hover {
  display: block;
  color: #fab200;
  border-top: 5px solid #fab200;
  cursor: pointer;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.tab-module .tab-view .tab-header-list .desktop-tabs {
    display: none;
  }
}
section.tab-module .tab-view .tab-header-list .mobile-tabs {
  display: none;
}
section.tab-module .tab-view .tab-header-list .mobile-tabs .custom-select {
  cursor: pointer;
  width: 80%;
  height: 38px;
  margin: 0 auto;
  position: relative;
}
section.tab-module .tab-view .tab-header-list .mobile-tabs .custom-select:after {
  content: '';
  background: transparent;
  border-top: 3px solid #64c0f9;
  border-right: 3px solid #64c0f9;
  -webkit-box-shadow: 0 0 0 lightgrey;
          box-shadow: 0 0 0 lightgrey;
  color: #64c0f9;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 12px;
  height: 12px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-transform: translate3d(0, 0, 0) rotate(135deg);
          transform: translate3d(0, 0, 0) rotate(135deg);
  pointer-events: none;
}
section.tab-module .tab-view .tab-header-list .mobile-tabs select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#f7f7f7), to(#c3ccd5));
  background-image: linear-gradient(to top, #f7f7f7, #c3ccd5);
  border: 0;
  border-radius: 0;
  color: #000000;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  padding: 7px 16px;
  letter-spacing: 0.23px;
  text-align: left;
  word-break: normal;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.tab-module .tab-view .tab-header-list .mobile-tabs {
    display: block;
  }
}
section.tab-module .tab-view .tab-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: auto;
}
section.tab-module .tab-view .tab-panel p {
  --color-text: #ffffff;
  --text-align-mq-large: center;
  --text-align-mq-small: center;
  color: var(--color-text);
  text-align: var(--text-align-mq-large);
  letter-spacing: 1px;
  margin: 0 auto;
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  section.tab-module .tab-view .tab-panel p {
    text-align: var(--text-align-mq-small);
  }
}
section.tab-module .tab-view .tab-panel .grid-item {
  --margin-top-mq-large: 50px;
  margin-top: var(--margin-top-mq-large);
}
section.tab-module .tab-view .tab-panel .grid-item .lazy-picture img,
section.tab-module .tab-view .tab-panel .grid-item .image-with-label img {
  height: 122px;
}
.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 100;
  display: var(--display-mq-large, flex);
}
@media (max-width: 1024px) {
  .popup-container {
    display: var(--display-mq-medium, flex);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .popup-container {
    display: var(--display-mq-small, flex);
  }
}
@media (max-width: 416px) {
  .popup-container {
    display: var(--display-mq-extra-small, flex);
  }
}
.popup-container .popup-dialog {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  border-radius: 0 0 5px 5px;
  width: 300px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 5px 2px #2b2c2d;
          box-shadow: 0px 1px 5px 2px #2b2c2d;
}
@media (min-width: 769px) and (min-height: 600px) {
  .popup-container .popup-dialog {
    width: 75vw;
  }
}
.popup-container .popup-dialog .exit-button {
  --font-size-mq-large:4em;--font-size-mq-medium:3em;--font-size-mq-small:2.7em;--font-size-mq-extra-small:2.7em;
  --line-height-mq-large:0.5;--line-height-mq-medium:0.5;--line-height-mq-small:0.5;--line-height-mq-extra-small:0.5;
  --font-stack: var(--font-stack-playoff);
  --color-text: #ffffff;
  color: var(--color-text);
  cursor: pointer;
  font-weight: normal;
  background: none;
  border: none;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  .popup-container .popup-dialog .exit-button {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .popup-container .popup-dialog .exit-button {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .popup-container .popup-dialog .exit-button {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
.popup-container .popup-dialog .popup-body {
  --background-x-mq-large:center;--background-x-mq-medium:center;--background-x-mq-small:center;--background-x-mq-extra-small:center;
  --background-y-mq-large:top;--background-y-mq-medium:top;--background-y-mq-small:top;--background-y-mq-extra-small:top;
  --background-size-mq-large:cover;--background-size-mq-medium:cover;--background-size-mq-small:cover;--background-size-mq-extra-small:cover;
  --background-repeat-mq-large:no-repeat;--background-repeat-mq-medium:no-repeat;--background-repeat-mq-small:no-repeat;--background-repeat-mq-extra-small:no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 25px;
  height: 250px;
}
@media (max-height: 599px) {
  .popup-container .popup-dialog .popup-body {
    background-image: var(--background-mq-extra-small);
    background-position: var(--background-x-mq-extra-small) var(--background-y-mq-extra-small);
    background-repeat: var(--background-repeat-mq-extra-small);
    background-size: var(--background-size-mq-extra-small);
  }
}
@media (min-height: 600px) {
  .popup-container .popup-dialog .popup-body {
    background-image: var(--background-mq-small);
    height: 600px;
  }
}
@media (min-width: 769px) and (min-height: 600px) {
  .popup-container .popup-dialog .popup-body {
    background-image: var(--background-mq-medium);
    height: calc(75vw * 0.5625);
  }
}
@media (min-width: 1025px) and (min-height: 600px) {
  .popup-container .popup-dialog .popup-body {
    background-image: var(--background-mq-large);
    padding: 35px;
  }
}
.popup-container .popup-dialog.legal {
  height: 300px;
  max-width: 700px;
  width: 100vw;
  background-color: #40424a;
  overflow-y: auto;
}
.popup-container .popup-dialog.legal::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
}
@media (max-width: 1024px) {
  .popup-container .popup-dialog.legal {
    max-height: 294px;
  }
}
.popup-container .popup-dialog.legal .exit-button {
  --line-height-mq-large:1rem;--line-height-mq-medium:1rem;--line-height-mq-small:1rem;--line-height-mq-extra-small:1rem;
  --padding-right-mq-large:1rem;--padding-right-mq-medium:1rem;--padding-right-mq-small:1rem;--padding-right-mq-extra-small:1rem;
  --padding-top-mq-large:1rem;--padding-top-mq-medium:1rem;--padding-top-mq-small:1rem;--padding-top-mq-extra-small:1rem;
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  .popup-container .popup-dialog.legal .exit-button {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .popup-container .popup-dialog.legal .exit-button {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .popup-container .popup-dialog.legal .exit-button {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
.popup-container .popup-dialog.legal .popup-body {
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  --color-text: #ffffff;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
  height: 200%;
  padding: 30px 25px;
  color: var(--color-text);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 1024px) {
  .popup-container .popup-dialog.legal .popup-body {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .popup-container .popup-dialog.legal .popup-body {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .popup-container .popup-dialog.legal .popup-body {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.loader {
  --bw: 6px;
  /* border width*/
  width: 64px;
  height: 64px;
  margin: 2.5rem;
  position: relative;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
.loader:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: conic-gradient(from 0.25turn, #f9b300, #ea8a19 0.17turn, #7c580b 0.7turn, rgba(204, 136, 4, 0) 0.75turn, rgba(204, 136, 4, 0));
  padding: var(--bw);
  border-radius: 50%;
  mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: source-out;
  mask-composite: exclude;
}
.loader-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
}
.loader-container img {
  max-width: 120px;
}
.dynamic-banner {
  background-image: var(--gradient-mq-large, none), var(--background-mq-large);
  background-position: var(--background-x-mq-large) var(--background-y-mq-large);
  background-repeat: var(--background-repeat-mq-large);
  background-size: var(--background-size-mq-large);
  min-height: calc(var(--height-mq-large) - 0px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 1024px) {
  .dynamic-banner {
    background-image: var(--gradient-mq-medium, none), var(--background-mq-medium);
    background-position: var(--background-x-mq-medium) var(--background-y-mq-medium);
    background-repeat: var(--background-repeat-mq-medium);
    background-size: var(--background-size-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .dynamic-banner {
    background-image: var(--gradient-mq-small, none), var(--background-mq-small);
    background-position: var(--background-x-mq-small) var(--background-y-mq-small);
    background-repeat: var(--background-repeat-mq-small);
    background-size: var(--background-size-mq-small);
  }
}
@media (max-width: 416px) {
  .dynamic-banner {
    background-image: var(--gradient-mq-extra-small, none), var(--background-mq-extra-small);
    background-position: var(--background-x-mq-extra-small) var(--background-y-mq-extra-small);
    background-repeat: var(--background-repeat-mq-extra-small);
    background-size: var(--background-size-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .dynamic-banner {
    min-height: calc(var(--height-mq-medium) - 0px);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .dynamic-banner {
    min-height: calc(var(--height-mq-small) - 0px);
  }
}
@media (max-width: 416px) {
  .dynamic-banner {
    min-height: calc(var(--height-mq-extra-small) - 0px);
  }
}
#root.paywall {
  height: 100vh;
  width: 100vw;
  background-color: #000000;
}
#root.paywall .logo {
  margin: 35px auto;
  height: 35px;
  width: 170px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root.paywall .paywall-content {
  margin: 0 auto;
  width: 426px;
  border-radius: 13px;
  overflow: hidden;
}
#root.paywall .paywall-content .poster {
  width: 100%;
}
#root.paywall .paywall-content span,
#root.paywall .paywall-content .heading {
  --color-background: unset;
}
#root.paywall .paywall-content .cta-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: -4px;
  --color-background: #ffffff;
  background-color: var(--color-background);
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
#root.paywall .paywall-content .cta-content .heading {
  --font-size-mq-large:26px;--font-size-mq-medium:26px;--font-size-mq-small:26px;--font-size-mq-extra-small:26px;
  --text-align-mq-large:center;--text-align-mq-medium:center;--text-align-mq-small:center;--text-align-mq-extra-small:center;
  max-width: 100%;
  margin-top: 24px;
  letter-spacing: 1px;
  line-height: 1.1;
  padding: 0 10px;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  #root.paywall .paywall-content .cta-content .heading {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  #root.paywall .paywall-content .cta-content .heading {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  #root.paywall .paywall-content .cta-content .heading {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
#root.paywall .paywall-content .cta-content h5.heading {
  --font-size-mq-large:19px;--font-size-mq-medium:19px;--font-size-mq-small:19px;--font-size-mq-extra-small:19px;
  margin-top: 5px;
}
#root.paywall .paywall-content .cta-content .upcoming-date {
  margin: 10px;
  padding: 3px 16px;
  color: #6c6d6f;
  white-space: nowrap;
}
#root.paywall .paywall-content .cta-content span {
  max-width: 100%;
  font-size: 16px;
  color: #2b2c2d;
  line-height: 1.31;
  text-align: center;
  overflow-wrap: anywhere;
}
#root.paywall .paywall-content .cta-content button {
  margin: 15px 0 20px 0;
}
#root.paywall .paywall-content .cta-content button.tertiary {
  --color-text: #eba114;
  color: var(--color-text);
  font-weight: bold;
  font-style: italic;
  letter-spacing: 1.4px;
  border: 3px solid var(--color-text);
}
#root.paywall .paywall-content .cta-content button,
#root.paywall .paywall-content .cta-content p {
  -ms-flex-item-align: center;
      align-self: center;
}
#root.paywall .paywall-content .cta-content p {
  --padding-left-mq-large:4px;--padding-left-mq-medium:4px;--padding-left-mq-small:4px;--padding-left-mq-extra-small:4px;
  --padding-right-mq-large:4px;--padding-right-mq-medium:4px;--padding-right-mq-small:4px;--padding-right-mq-extra-small:4px;
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
}
@media (max-width: 1024px) {
  #root.paywall .paywall-content .cta-content p {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  #root.paywall .paywall-content .cta-content p {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  #root.paywall .paywall-content .cta-content p {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
#root.paywall .paywall-content .info-buttons {
  background-color: #ffffff;
  border-top: 1px solid #dcdddf;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root.paywall .paywall-content .info-buttons button {
  --color-text: #0063cf;
  --font-weight: 600;
  --letter-spacing: 2px;
  -ms-flex-item-align: center;
      align-self: center;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
#root.paywall .spinner {
  border: 6px solid #ea8a19;
  border-radius: 50%;
  margin: 50px auto;
  height: 64px;
  width: 64px;
  border-top-color: transparent;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
body.copy-update {
  --line-height-mq-large:1.5;--line-height-mq-medium:1.5;--line-height-mq-small:1.5;--line-height-mq-extra-small:1.5;
}
body.copy-update,
body.copy-update .grid.side-align > .grid-item.side-align-content-container,
body.copy-update .hero-dual > .content,
body.copy-update .footer-main .heading {
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
body.copy-update h1,
body.copy-update .grid.side-align > .grid-item.side-align-content-container h1,
body.copy-update .hero-dual > .content h1,
body.copy-update .footer-main .heading h1 {
  --font-size-mq-large:60px;--font-size-mq-medium:45px;--font-size-mq-small:30px;--font-size-mq-extra-small:30px;
  --letter-spacing-mq-large:6px;--letter-spacing-mq-medium:4.5px;--letter-spacing-mq-small:3px ;--letter-spacing-mq-extra-small:3px ;
  --line-height-mq-large:54px;--line-height-mq-medium:40.5px;--line-height-mq-small:27px;--line-height-mq-extra-small:27px;
}
body.copy-update h2,
body.copy-update .grid.side-align > .grid-item.side-align-content-container h2,
body.copy-update .hero-dual > .content h2,
body.copy-update .footer-main .heading h2 {
  --font-size-mq-large:45px;--font-size-mq-medium:37px;--font-size-mq-small:22px;--font-size-mq-extra-small:22px;
  --letter-spacing-mq-large:2.7px;--letter-spacing-mq-medium:2.22px;--letter-spacing-mq-small:1.32px;--letter-spacing-mq-extra-small:1.32px;
  --line-height-mq-large:66px;--line-height-mq-medium:53px;--line-height-mq-small:32px;--line-height-mq-extra-small:32px;
}
body.copy-update h3,
body.copy-update .grid.side-align > .grid-item.side-align-content-container h3,
body.copy-update .hero-dual > .content h3,
body.copy-update .footer-main .heading h3 {
  --font-size-mq-large:40px;--font-size-mq-medium:32px;--font-size-mq-small:20px;--font-size-mq-extra-small:20px;
  --letter-spacing-mq-large:2.4px;--letter-spacing-mq-medium:1.92px;--letter-spacing-mq-small:1.2px;--letter-spacing-mq-extra-small:1.2px;
  --line-height-mq-large:58px;--line-height-mq-medium:47px;--line-height-mq-small:29px;--line-height-mq-extra-small:29px;
}
body.copy-update h4,
body.copy-update .grid.side-align > .grid-item.side-align-content-container h4,
body.copy-update .hero-dual > .content h4,
body.copy-update .footer-main .heading h4 {
  --font-size-mq-large:25px;--font-size-mq-medium:22px;--font-size-mq-small:18px ;--font-size-mq-extra-small:18px ;
  --letter-spacing-mq-large:0px;--letter-spacing-mq-medium:0px;--letter-spacing-mq-small:0px;--letter-spacing-mq-extra-small:0px;
  --line-height-mq-large:30px;--line-height-mq-medium:27px;--line-height-mq-small:22px;--line-height-mq-extra-small:22px;
  --font-weight: 500;
  --font-stack: var(--font-stack-benton);
  --font-style: normal;
  text-transform: none;
}
body.copy-update h5,
body.copy-update .grid.side-align > .grid-item.side-align-content-container h5,
body.copy-update .hero-dual > .content h5,
body.copy-update .footer-main .heading h5 {
  --font-size-mq-large:25px;--font-size-mq-medium:22px;--font-size-mq-small:18px;--font-size-mq-extra-small:18px;
  --letter-spacing-mq-large:1.5px;--letter-spacing-mq-medium:1.32px;--letter-spacing-mq-small:1.08px;--letter-spacing-mq-extra-small:1.08px;
  --line-height-mq-large:37px;--line-height-mq-medium:32px;--line-height-mq-small:25px;--line-height-mq-extra-small:25px;
}
body.copy-update p,
body.copy-update .grid.side-align > .grid-item.side-align-content-container p,
body.copy-update .hero-dual > .content p,
body.copy-update .footer-main .heading p {
  --font-size-mq-large:16px;--font-size-mq-medium:16px;--font-size-mq-small:16px;--font-size-mq-extra-small:16px;
  --letter-spacing-mq-large:0px;--letter-spacing-mq-medium:0px;--letter-spacing-mq-small:0px;--letter-spacing-mq-extra-small:0px;
  --line-height-mq-large:24px;--line-height-mq-medium:24px;--line-height-mq-small:24px;--line-height-mq-extra-small:24px;
}
body.copy-update .legal-copy,
body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy,
body.copy-update .hero-dual > .content .legal-copy,
body.copy-update .footer-main .heading .legal-copy,
body.copy-update p.legal-copy,
body.copy-update .grid.side-align > .grid-item.side-align-content-container p.legal-copy,
body.copy-update .hero-dual > .content p.legal-copy,
body.copy-update .footer-main .heading p.legal-copy,
body.copy-update span.legal-copy,
body.copy-update .grid.side-align > .grid-item.side-align-content-container span.legal-copy,
body.copy-update .hero-dual > .content span.legal-copy,
body.copy-update .footer-main .heading span.legal-copy,
body.copy-update .legal-copy p,
body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy p,
body.copy-update .hero-dual > .content .legal-copy p,
body.copy-update .footer-main .heading .legal-copy p,
body.copy-update .legal-copy span,
body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy span,
body.copy-update .hero-dual > .content .legal-copy span,
body.copy-update .footer-main .heading .legal-copy span {
  --font-size-mq-large:10px;--font-size-mq-medium:10px;--font-size-mq-small:10px;--font-size-mq-extra-small:10px;
  --line-height-mq-large:12px;--line-height-mq-medium:12px;--line-height-mq-small:12px;--line-height-mq-extra-small:12px;
  --color-text: rgba(153, 153, 153, 0.4);
  --font-stack: var(--font-stack-benton);
  --font-weight: bold;
  letter-spacing: 0px;
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
}
@media (max-width: 1024px) {
  body.copy-update .legal-copy,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy,
  body.copy-update .hero-dual > .content .legal-copy,
  body.copy-update .footer-main .heading .legal-copy,
  body.copy-update p.legal-copy,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container p.legal-copy,
  body.copy-update .hero-dual > .content p.legal-copy,
  body.copy-update .footer-main .heading p.legal-copy,
  body.copy-update span.legal-copy,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container span.legal-copy,
  body.copy-update .hero-dual > .content span.legal-copy,
  body.copy-update .footer-main .heading span.legal-copy,
  body.copy-update .legal-copy p,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy p,
  body.copy-update .hero-dual > .content .legal-copy p,
  body.copy-update .footer-main .heading .legal-copy p,
  body.copy-update .legal-copy span,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy span,
  body.copy-update .hero-dual > .content .legal-copy span,
  body.copy-update .footer-main .heading .legal-copy span {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  body.copy-update .legal-copy,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy,
  body.copy-update .hero-dual > .content .legal-copy,
  body.copy-update .footer-main .heading .legal-copy,
  body.copy-update p.legal-copy,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container p.legal-copy,
  body.copy-update .hero-dual > .content p.legal-copy,
  body.copy-update .footer-main .heading p.legal-copy,
  body.copy-update span.legal-copy,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container span.legal-copy,
  body.copy-update .hero-dual > .content span.legal-copy,
  body.copy-update .footer-main .heading span.legal-copy,
  body.copy-update .legal-copy p,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy p,
  body.copy-update .hero-dual > .content .legal-copy p,
  body.copy-update .footer-main .heading .legal-copy p,
  body.copy-update .legal-copy span,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy span,
  body.copy-update .hero-dual > .content .legal-copy span,
  body.copy-update .footer-main .heading .legal-copy span {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  body.copy-update .legal-copy,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy,
  body.copy-update .hero-dual > .content .legal-copy,
  body.copy-update .footer-main .heading .legal-copy,
  body.copy-update p.legal-copy,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container p.legal-copy,
  body.copy-update .hero-dual > .content p.legal-copy,
  body.copy-update .footer-main .heading p.legal-copy,
  body.copy-update span.legal-copy,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container span.legal-copy,
  body.copy-update .hero-dual > .content span.legal-copy,
  body.copy-update .footer-main .heading span.legal-copy,
  body.copy-update .legal-copy p,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy p,
  body.copy-update .hero-dual > .content .legal-copy p,
  body.copy-update .footer-main .heading .legal-copy p,
  body.copy-update .legal-copy span,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container .legal-copy span,
  body.copy-update .hero-dual > .content .legal-copy span,
  body.copy-update .footer-main .heading .legal-copy span {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  body.copy-update,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container,
  body.copy-update .hero-dual > .content,
  body.copy-update .footer-main .heading {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  body.copy-update,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container,
  body.copy-update .hero-dual > .content,
  body.copy-update .footer-main .heading {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  body.copy-update,
  body.copy-update .grid.side-align > .grid-item.side-align-content-container,
  body.copy-update .hero-dual > .content,
  body.copy-update .footer-main .heading {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
.richtext span {
  --padding-left-mq-large:0px;--padding-left-mq-medium:0px;--padding-left-mq-small:0px;--padding-left-mq-extra-small:0px;
  --padding-right-mq-large:0px;--padding-right-mq-medium:0px;--padding-right-mq-small:0px;--padding-right-mq-extra-small:0px;
}
.card-container {
  background-image: var(--gradient-mq-large, none), var(--background-mq-large);
  background-position: var(--background-x-mq-large) var(--background-y-mq-large);
  background-repeat: var(--background-repeat-mq-large);
  background-size: var(--background-size-mq-large);
  border-top: var(--border-top-width-mq-large) var(--border-top-style-mq-large) var(--border-top-color-mq-large);
  border-right: var(--border-right-width-mq-large) var(--border-right-style-mq-large) var(--border-right-color-mq-large);
  border-bottom: var(--border-bottom-width-mq-large) var(--border-bottom-style-mq-large) var(--border-bottom-color-mq-large);
  border-left: var(--border-left-width-mq-large) var(--border-left-style-mq-large) var(--border-left-color-mq-large);
  /*
  // Note: safe to use with image backgrounds.
  // Image-based backgrounds generate inline and will cascade over the gradient.
  */
  background-image: var(--gradient-mq-large, none);
  min-height: calc(var(--height-mq-large) - 0px);
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
  color: var(--color-text);
  background-color: var(--color-background);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1024px) {
  .card-container {
    background-image: var(--gradient-mq-medium, none), var(--background-mq-medium);
    background-position: var(--background-x-mq-medium) var(--background-y-mq-medium);
    background-repeat: var(--background-repeat-mq-medium);
    background-size: var(--background-size-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .card-container {
    background-image: var(--gradient-mq-small, none), var(--background-mq-small);
    background-position: var(--background-x-mq-small) var(--background-y-mq-small);
    background-repeat: var(--background-repeat-mq-small);
    background-size: var(--background-size-mq-small);
  }
}
@media (max-width: 416px) {
  .card-container {
    background-image: var(--gradient-mq-extra-small, none), var(--background-mq-extra-small);
    background-position: var(--background-x-mq-extra-small) var(--background-y-mq-extra-small);
    background-repeat: var(--background-repeat-mq-extra-small);
    background-size: var(--background-size-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .card-container {
    border-top: var(--border-top-width-mq-medium) var(--border-top-style-mq-medium) var(--border-top-color-mq-medium);
    border-right: var(--border-right-width-mq-medium) var(--border-right-style-mq-medium) var(--border-right-color-mq-medium);
    border-bottom: var(--border-bottom-width-mq-medium) var(--border-bottom-style-mq-medium) var(--border-bottom-color-mq-medium);
    border-left: var(--border-left-width-mq-medium) var(--border-left-style-mq-medium) var(--border-left-color-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .card-container {
    border-top: var(--border-top-width-mq-small) var(--border-top-style-mq-small) var(--border-top-color-mq-small);
    border-right: var(--border-right-width-mq-small) var(--border-right-style-mq-small) var(--border-right-color-mq-small);
    border-bottom: var(--border-bottom-width-mq-small) var(--border-bottom-style-mq-small) var(--border-bottom-color-mq-small);
    border-left: var(--border-left-width-mq-small) var(--border-left-style-mq-small) var(--border-left-color-mq-small);
  }
}
@media (max-width: 416px) {
  .card-container {
    border-top: var(--border-top-width-mq-extra-small) var(--border-top-style-mq-extra-small) var(--border-top-color-mq-extra-small);
    border-right: var(--border-right-width-mq-extra-small) var(--border-right-style-mq-extra-small) var(--border-right-color-mq-extra-small);
    border-bottom: var(--border-bottom-width-mq-extra-small) var(--border-bottom-style-mq-extra-small) var(--border-bottom-color-mq-extra-small);
    border-left: var(--border-left-width-mq-extra-small) var(--border-left-style-mq-extra-small) var(--border-left-color-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .card-container {
    background-image: var(--gradient-mq-medium, var(--gradient-mq-large, none));
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .card-container {
    background-image: var(--gradient-mq-small, var(--gradient-mq-medium, var(--gradient-mq-large, none)));
  }
}
@media (max-width: 416px) {
  .card-container {
    background-image: var(--gradient-mq-extra-small, var(--gradient-mq-small, var(--gradient-mq-medium, var(--gradient-mq-large, none))));
  }
}
@media (max-width: 1024px) {
  .card-container {
    min-height: calc(var(--height-mq-medium) - 0px);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .card-container {
    min-height: calc(var(--height-mq-small) - 0px);
  }
}
@media (max-width: 416px) {
  .card-container {
    min-height: calc(var(--height-mq-extra-small) - 0px);
  }
}
@media (max-width: 1024px) {
  .card-container {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .card-container {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .card-container {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .card-container {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .card-container {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .card-container {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
.cards-wrapper {
  --overflow-padding: 2.1rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  overflow-x: auto;
  padding-top: var(--overflow-padding);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.cards-wrapper::-webkit-scrollbar {
  display: none;
}
.cards-wrapper .card {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media (min-width: 1025px) {
  .cards-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: var(--flex-direction-mq-large);
            flex-direction: var(--flex-direction-mq-large);
  }
}
@media (max-width: 1024px) {
  .cards-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: var(--flex-direction-mq-medium);
            flex-direction: var(--flex-direction-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .cards-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: var(--flex-direction-mq-small);
            flex-direction: var(--flex-direction-mq-small);
  }
}
@media (max-width: 416px) {
  .cards-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: var(--flex-direction-mq-extra-small);
            flex-direction: var(--flex-direction-mq-extra-small);
  }
}
.card {
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  --card-padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.8rem;
  position: relative;
  z-index: 0;
  padding: var(--card-padding);
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  max-width: 425px;
}
@media (max-width: 1024px) {
  .card {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .card {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .card {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
.card::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 10px;
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.card.disney-bundle-gradient::before {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-45%, #0063e5), color-stop(23%, #038cb5), color-stop(95%, #fab200));
  background: linear-gradient(to bottom, #0063e5 -45%, #038cb5 23%, #fab200 95%);
}
.card.espn-gold-gradient::before {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-45%, #fa9500), color-stop(95%, #fab200));
  background: linear-gradient(to bottom, #fa9500 -45%, #fab200 95%);
}
.card button {
  width: 100%;
  --margin-left-mq-large:0;--margin-left-mq-medium:0;--margin-left-mq-small:0;--margin-left-mq-extra-small:0;
  --margin-right-mq-large:0;--margin-right-mq-medium:0;--margin-right-mq-small:0;--margin-right-mq-extra-small:0;
}
.card .modal-interact {
  place-self: center;
}
.card ul.value-props {
  --margin-left-mq-large:0;--margin-left-mq-medium:0;--margin-left-mq-small:0;--margin-left-mq-extra-small:0;
  --margin-right-mq-large:0;--margin-right-mq-medium:0;--margin-right-mq-small:0;--margin-right-mq-extra-small:0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: calc(var(--card-padding) + 15px);
  /* default asset width */
}
.card > :first-child {
  margin-top: 1rem;
}
.badge {
  position: absolute;
  top: 0%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 1rem 1rem 0.7rem;
  background: #013265;
  border-radius: 10px;
}
.badge::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 10px;
  background: -webkit-gradient(linear, left top, right top, color-stop(-45%, #0063e5), color-stop(45%, #038cb5), color-stop(110%, #fab200));
  background: linear-gradient(to right, #0063e5 -45%, #038cb5 45%, #fab200 110%);
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.disney-bundle-gradient .badge::before {
  background: -webkit-gradient(linear, left top, right top, color-stop(-45%, #0063e5), color-stop(45%, #038cb5), color-stop(110%, #fab200));
  background: linear-gradient(to right, #0063e5 -45%, #038cb5 45%, #fab200 110%);
}
.espn-gold-gradient .badge::before {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-45%, #fa9500), color-stop(95%, #fab200));
  background: linear-gradient(to bottom, #fa9500 -45%, #fab200 95%);
}
.value-props {
  margin: var(--margin-top-mq-large) var(--margin-right-mq-large) var(--margin-bottom-mq-large) var(--margin-left-mq-large);
  padding: var(--padding-top-mq-large) var(--padding-right-mq-large) var(--padding-bottom-mq-large) var(--padding-left-mq-large);
  color: var(--color-text);
  background-color: var(--color-background);
  font-family: var(--font-stack);
  font-size: var(--font-size-mq-large);
  line-height: var(--line-height-mq-large);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  -webkit-text-decoration: var(--text-decoration, none);
          text-decoration: var(--text-decoration, none);
  text-align: var(--text-align-mq-large);
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.75rem;
}
@media (max-width: 1024px) {
  .value-props {
    margin: var(--margin-top-mq-medium) var(--margin-right-mq-medium) var(--margin-bottom-mq-medium) var(--margin-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .value-props {
    margin: var(--margin-top-mq-small) var(--margin-right-mq-small) var(--margin-bottom-mq-small) var(--margin-left-mq-small);
  }
}
@media (max-width: 416px) {
  .value-props {
    margin: var(--margin-top-mq-extra-small) var(--margin-right-mq-extra-small) var(--margin-bottom-mq-extra-small) var(--margin-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .value-props {
    padding: var(--padding-top-mq-medium) var(--padding-right-mq-medium) var(--padding-bottom-mq-medium) var(--padding-left-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .value-props {
    padding: var(--padding-top-mq-small) var(--padding-right-mq-small) var(--padding-bottom-mq-small) var(--padding-left-mq-small);
  }
}
@media (max-width: 416px) {
  .value-props {
    padding: var(--padding-top-mq-extra-small) var(--padding-right-mq-extra-small) var(--padding-bottom-mq-extra-small) var(--padding-left-mq-extra-small);
  }
}
@media (max-width: 1024px) {
  .value-props {
    font-size: var(--font-size-mq-medium);
    text-align: var(--text-align-mq-medium);
    line-height: var(--line-height-mq-medium);
  }
}
@media (max-width: 768px), (max-width: 812px) and (orientation: landscape) {
  .value-props {
    font-size: var(--font-size-mq-small);
    text-align: var(--text-align-mq-small);
    line-height: var(--line-height-mq-small);
  }
}
@media (max-width: 416px) {
  .value-props {
    font-size: var(--font-size-mq-extra-small);
    text-align: var(--text-align-mq-extra-small);
    line-height: var(--line-height-mq-extra-small);
  }
}
.base-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 9999px;
  cursor: pointer;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
  font-family: var(--font-stack-benton);
  letter-spacing: 0.1em;
}
.base-label:hover {
  background-color: rgb(39 75 112);
}
.base-label input:checked ~ .base-label {
  background-color: rgb(0 119 204);
}
fieldset.pill-radio-group {
  width: 320px;
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 9999px;
  background-color: rgb(15 55 96);
}
fieldset.pill-radio-group .pill-entry {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
          flex: 1 1 0%;
}
fieldset.pill-radio-group input.pill-input {
  pointer-events: none;
  position: fixed;
  opacity: 0;
}
label.pill-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 9999px;
  cursor: pointer;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
  font-family: var(--font-stack-benton);
  letter-spacing: 0.1em;
  height: 1.5rem;
  font-size: 0.7em;
  line-height: 1rem;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}
label.pill-label:hover {
  background-color: rgb(39 75 112);
}
input:checked + label.pill-label {
  background-color: rgb(0 119 204);
}
